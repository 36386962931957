﻿/*========== Media Queries ==========*/
$mobile-sm: "only screen and (max-width: 374px)";
$mobile: "only screen and (max-width: 767px)";
$non-mobile: "only screen and (min-width: 768px)";
$tablet: "only screen and (min-width: 768px ) and (max-width: 991px)";
$desktop: "only screen and (min-width: 992px)";
$desktop-sm: "only screen and (min-width: 1024px)";
$desktop-lg: "only screen and (min-width: 1200px)";
$desktop-xl: "only screen and (min-width: 1441px)";
$desktop-super-xl: "only screen and (min-width: 1920px)";
$desktop-sm-only: "only screen and (min-width: 1025px) and (max-width: 1199px)";
$tablet-desktop-sm: "only screen and (min-width: 768px) and (max-width: 1199px)";
$desktop-max: "only screen and (min-width: 1600px)";


/*========== Colors ==========*/
$bodyGray: #676767;
$ltGray: #f1f1f2;

$errorRed: #ff0000;

$ltBlue: #e7f5fa;
$mdBlue: #526ee5;


$orange: #e19228;
$light-yellow: #fefcf9;
$light-green: #f3fbf9;
$ltGreen: #e3f0ed;
$mdGreen: #31b69e;
$contentNavGreen: #00643c;
$green: #008a5e;


/*========== Fonts ==========*/
$barlow: 'Barlow', sans-serif;
