﻿#pediatrics-accordion-second {
    padding-top: 3rem;

    @media #{$non-mobile} {
        padding-top: 10rem;
    }

    sup {
        font-size: 65%;
    }

    h3 {
        color: $green;
        margin-bottom: 3rem;
        border-bottom: 1px solid #707070;
        padding-bottom: 1rem;

        @media screen and (min-width: 764px) {
            max-width: 75.4rem;
            margin: 3rem auto;
        }
    }

    h4 {
        text-align: center;
        margin-bottom: 3rem;
        margin-top: 3rem;
        font-size: 1.8rem;

        @media screen and (min-width: 764px) {
            max-width: 75.4rem;
            margin: 5rem auto 3rem auto;
            font-size: 2.2rem;
            line-height: 2.6rem;
        }
    }

    .copy {
        > ul {
            @media screen and (min-width: 764px) {
                max-width: 75.4rem;
                margin: 0 auto;
            }
        }
    }


    .subcopy {
        font-weight: 700;
        margin-bottom: 3rem;

        @media screen and (min-width: 764px) {
            max-width: 75.4rem;
            margin: 0 auto 3rem auto;
            font-size: 1.6rem;
            line-height: 2rem;
        }
    }



    ul li {
        padding: 0 0 1rem 2rem;
        text-indent: -1.8rem;



        &::before {
            content: "• ";
            color: #e19228;
            padding-right: 1rem;
        }

        li {
            padding-top: 1rem;
            text-indent: -1.3rem;

            &::before {
                content: none;
            }
        }
    }

    .footnote {
        font-size: 10px;
        margin-bottom: 1rem;

        @media #{$non-mobile} {
            font-size: 14px;
        }
    }

    .accordion .copy {
        padding: 0 2rem;

        @media #{$non-mobile} {
            padding: 0 1.5rem 0;
        }
    }

    .study-6-month {
        background-image: url(/dist/images/steps-green-pediatrics-mobile.webp);
        background-repeat: no-repeat;
        width: 30.9rem;
        height: 66.7rem;
        color: #fff;
        display: flex;
        flex-direction: column;
        margin: 0 auto 3rem;
        transition: opacity .5s ease-in;

        &.lazy {
            background-image: none;
            opacity: 0;
        }

        @media #{$non-mobile} {
            background-image: url(/dist/images/steps-green-pediatrics-desktop.webp);
            background-repeat: no-repeat;
            width: 80rem;
            height: 27.5rem;
            flex-direction: row;
            margin: 0 auto 5rem;
            position: relative;
            left: 2rem;
        }
    }

    .month-sec {
        div {

            &:nth-child(1) {
                position: relative;
                transform: rotate(0);
                left: 11rem;
                top: 1.4rem;
                font-weight: 700;

                @media #{$non-mobile} {
                    transform: rotate( -90deg );
                    left: 10.5rem;
                }
            }

            .heading {
                font-size: 1.6rem;
                font-weight: 700;
                margin-bottom: 1.2rem;

                @media #{$non-mobile} {
                    font-size: 1.8rem;
                }
            }

            span {
                display: block;
                margin-bottom: 1rem;
            }

            &.first-col {
                margin-top: 50px;

                @media #{$non-mobile} {
                    margin-left: -3rem;
                }

                span {
                    font-size: 1.2rem;
                    font-weight: 700;

                    @media #{$non-mobile} {
                        font-size: 1.4rem;
                    }
                }

                .heading {
                    margin-bottom: .5rem;
                }

                .border {
                    max-width: 20rem;
                    margin: 0 auto;
                    text-align: left;
                    font-weight: 500;

                    @media #{$non-mobile} {
                        max-width: 100%;
                    }
                }
            }

            &.second-col {
                margin: 70px auto 0;

                @media #{$non-mobile} {
                    margin: auto;

                    .heading {
                        margin-bottom: 1rem;
                    }
                }
            }

            .border {
                border-top: 1px solid #fff;
                padding-top: .5rem;
                max-width: 12rem;
                margin: 0 auto;
            }

            p {
                font-weight: 700;
            }

            ul {
                margin: 1rem 0;
                padding: 0 2rem 0 5rem;

                @media #{$non-mobile} {
                    padding: 0 2rem 0 2rem;
                }

                li {
                    padding: 0;
                    text-indent: -1.2rem;
                    text-align: left;

                    &::before {
                        content: '—';
                        color: #FFFFFF;
                        padding-right: 0;
                    }
                }
            }
        }

        .col {
            text-align: center;
            margin: 45px auto 0;

            @media #{$non-mobile} {
                margin: auto;
            }
        }
    }
}
