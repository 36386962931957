﻿.dosing-administration {
  background-color: #E3F0ED;
  padding: 2rem 0 3rem;

  @media #{$non-mobile} {
    padding: 7rem 0;
  }

  h2 {
    text-align: center;
    margin: 3rem auto 4rem auto;

    @media (min-width: 641px) {
      margin: 0 0 5.5rem;
    }
  }

  .copy {
    font-weight: 700;
  }

  h4 {
    margin-bottom: 2rem;
    font-size: 1.6rem;
    padding: 0 .4rem;

    @media #{$non-mobile} {
      font-size: 2.2rem;
    }
  }

  ul li {
    padding: 0 0 2rem 2rem;
    text-indent: -1.8rem;
    line-height: 1.8rem;

    @media #{$non-mobile} {
      line-height: 2rem;
    }

    &::before {
      content: "• ";
      color: #e19228;
      padding-right: 1rem;
    }

    li {
      padding-top: 1rem;

      &::before {
        content: none;
      }
    }
  }

  .white-callout {
    background-color: #fff;
    border-radius: 1rem;
    max-width: 74.1rem;
    padding: 3rem 1rem;
    margin: 1rem auto 4rem;
    text-align: center;

    @media #{$non-mobile} {
      margin: 4rem auto;
      padding: 4rem 1rem;
    }

    h4 {
      text-align: center;
      margin-bottom: 5rem;
      color:#00402C ;
    }

    + h4 {
      font-size: 1.8rem;
    }

    .weight-sec {
      display: flex;
      justify-content: center;

      div {
        max-width: 11rem;
        margin-right: 3rem;

        @media #{$non-mobile} {
          max-width: 18rem;
          margin-right: 4rem;
        }

        &:last-child {
          margin-right: 0;
        }

        p {
          font-weight: 500;
          border-top: 1px solid #676767;
          position: relative;
          padding-top: 1rem;

          &:nth-child(1) {
            min-height: 8rem;
          }

          &.equal, &.multiply, &.divide {
            &:after {
              content: '=';
              font-size: 20px;
              font-weight: 700;
              position: absolute;
              top: -22px;
              right: -22px;
              font-weight: 700;
            }
          }

          &.multiply {
            &:after {
              content: '\00D7';
            }
          }

          &.divide {
            &:after {
              content: '\00F7';
            }
          }

          span {
            font-weight: 700;

            &.green {
              color: $green;
            }

            &.blue {
              color: #009DDC;
            }
          }
        }
      }
    }
  }

  .mobile-none {
    display: none;

    @media #{$non-mobile} {
      display: block;
    }
  }
}
