﻿$dkGray: #58595B;

#gattex-moa-video {
  margin: 3rem 0 6rem 0;

  @media (min-width: 768px) {
    margin-top: 6rem;
  }

  h3 {
    color: $dkGray;
    text-align: center;
    margin-bottom: 2rem;
  }

  p {
    text-align: center;
    margin-bottom: 2rem;

    @media (min-width: 768px) {
      margin-top: 2rem;
      margin-bottom: 4rem;
    }
  }

  #brightcove-player {
    margin: 0 auto;
    max-width: 91.6rem;

    .video-js {
      width: 100%;
      height: 21.1rem;
      overflow: hidden;
      background-color: transparent !important;

      @media (min-width: 768px) {
        height: 51.5rem;
      }
    }

    .bc-player-SykGTiz2b_default:hover .vjs-big-play-button {
      background-color: $green;
    }

    .vjs-poster {
      background-color: transparent;
    }
  }
}
