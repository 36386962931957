﻿#pediatrics-accordion-third {
    padding-top: 3rem;
    padding-bottom: 3rem;

    @media #{$non-mobile} {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    .table-container {
        padding: 1.5rem 0;

        @media #{$non-mobile} {
            padding: 5rem 0;
            max-width: 75.5rem;
            margin: 0 auto;
        }

        h4 {
            text-align: center;
            margin-bottom: 1rem;
            font-size: 1.8rem;

            @media #{$non-mobile} {
                font-size: 2.2rem;
                margin-bottom: 3rem;
            }
        }

        .table {
            width: 100%;
            border-collapse: collapse;
            color: #254331;
            margin-bottom: 2rem;
            font-size: .7rem;

            @media (min-width: 769px) {
                font-size: inherit;
                margin-bottom: 5rem;
            }

            thead {
                tr {
                    th {
                        font-weight: 700;
                        border-bottom: 1px solid #c6c5c5;
                        padding: 0;
                        text-align: center;
                        vertical-align: middle;
                        font-size: 1.1rem;

                        @media (min-width: 769px) {
                            font-size: inherit;
                        }

                        &:first-of-type {
                            text-align: left;
                        }
                    }
                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid #c6c5c5;

                    &:last-of-type {
                        border-bottom: none;
                    }

                    &.heading {
                        background-color: #ECF3F0;

                        td {
                            color: #258961;
                        }
                    }

                    td {
                        font-weight: 700;
                        padding: 0.3rem 0;
                        text-align: center;
                        font-size: .9rem;

                        @media (min-width: 769px) {
                            font-size: 1.2rem;
                        }

                        &:first-of-type {
                            text-align: left;
                            padding-left: 0.3rem;
                        }

                        &:last-of-type {
                            color: #008A5E;
                        }

                        span {
                            display: block;
                            padding-left: 1.5rem;
                            font-style: italic;
                        }
                    }

                    &.inset {
                        td {
                            &:first-of-type {
                                padding-left: 1.5rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .blue-sec {
        border-top: 3px solid #2d9ae3;
        border-bottom: 3px solid #2d9ae3;
        padding: 3rem;
        text-align: center;
        margin: 3rem auto;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 101.5rem;
        width: 100%;

        @media (min-width: 768px) {
            padding: 3rem 1rem;
        }

        p {
            color: #2d9ae3;
            font-size: 1.6rem;
            font-weight: 500;

            strong {
                font-weight: 700;
            }

            @media (min-width: 768px) {
                font-size: 2.4rem;
                max-width: none;
            }
        }

        a {
            color: #2d9ae3;
        }
    }

    .accordion .copy {
        padding: 0 1rem;
    }
}
