﻿.volume-over-time {
  padding-bottom: 3rem;

  @media screen and (min-width: 768px) {
    padding-bottom: 9rem;

    .about-pediatrics-page & {
      padding-bottom: 7rem;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 3rem;

    @media screen and (min-width: 768px) {
      max-width: 83rem;
      margin: 0 auto 4rem auto;

      &:after {
        bottom: -2.2rem;
      }
    }

    .about-pediatrics-page & {
      max-width: none;

      + p {
        font-size: 1.4rem;
        line-height: 2rem;
        padding: 0px 4rem;
        text-align: center;
        font-weight: 500;
        margin-bottom: 0;

        @media screen and (min-width: 768px) {
          font-size: 1.6rem;
          max-width: none;
        }
      }
      /*@media screen and (min-width: 768px) {
                &:after {
                    bottom: -2.2rem;
                }
            }*/
    }
    /*.about-adults-page & {
            @media screen and (min-width: 768px) {
                &:after {
                    bottom: -2.2rem;
                }
            }
        }*/
  }

  h3 {
    color: #58595B;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;

    @media screen and (min-width: 768px) {
      font-size: 2.2rem;
      line-height: 2.2rem;
    }
  }

  h4 {
    color: #00402C;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 1rem;

    @media screen and (min-width: 768px) {
      max-width: 76.8rem;
      margin: 0 auto 2rem auto;
      font-size: 1.6rem;
    }
  }

  p {
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #464749;
    margin-bottom: 2rem;

    @media screen and (min-width: 768px) {
      max-width: 76.8rem;
      margin: 0 auto 2rem auto;
      font-size: 1.6rem;
    }
  }

  .blue-sec {
    color: #2D9AE3;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 700;
    padding: 2rem;
    margin: 1rem auto;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .disclaimer {
    font-size: 1rem;
    margin-bottom: 0;
    line-height: 1.5rem;

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }

  .chart {
    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: center;
      max-width: 98.8rem;
      margin: 5rem auto 4rem auto;

      .about-pediatrics-page & {
        margin-top: 2rem;
        margin-bottom: 0;
      }
    }

    .steps-chart-reduction-value {
      display: none;

      @media screen and (min-width: 768px) {
        display: flex;
        align-items: flex-end;

        .figures {
          font-size: 4.4rem;
          line-height: 3rem;
          font-weight: 700;
          color: #008A5E;
          position: relative;
          margin-left: 2rem;
          margin-bottom: 10rem;

          span {
            font-size: 50%;
          }

          &:before {
            content: '';
            width: 2.2rem;
            height: 6.9rem;
            position: absolute;
            left: -2rem;
            top: -2rem;
            background-image: url(/dist/images/arrow-down-green.svg);
            background-repeat: no-repeat;
          }
        }
      }
    }

    .about-pediatrics-page & {
      width: 100%;
      max-width: 73rem;

      #gattex_chartPeds_svg .barlow-font {
        font-family: "Barlow", Arial, sans-serif;
        font-weight: 700;
      }
    }
  }
}
