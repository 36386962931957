﻿.safety-profile {
  padding: 3rem 0;
  background-color: #E3F0ED;

  @media #{$non-mobile} {
    padding: 10rem 0;
  }


  h2 {
    text-align: center;
    padding: 0;
    margin-bottom: 5rem;

    &:after {
      margin-bottom: -1rem;
    }

    @media #{$non-mobile} {
      background-color: #E3F0ED;
      margin-bottom: 5rem;
      padding: 0 1rem;

      &:after {
        bottom: -2rem;
      }
    }
  }

  h4 {
    font-size: 1.6rem;

    @media #{$non-mobile} {
      font-size: 2.2rem;
    }
  }

  .pediatrics-subhead {
    margin-bottom: 3rem;
    /*@media #{$non-mobile} {
            margin-bottom: 5rem;
        }*/
  }

  .table {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    border-collapse: collapse;
    margin: 3rem 0;

    @media #{$non-mobile} {
      margin: 5rem 0;
    }

    tr {
      td, th {
        border-bottom: 0.25px solid #464749;
        height: 30px;
        vertical-align: middle;

        &:nth-child(1) {
          color: #093220;
          text-align: left;
        }

        &:nth-child(2) {
          color: #5a5b5e;
          text-align: center;
        }

        &:nth-child(3) {
          color: #00794b;
          text-align: center;
        }
      }

      th {
        border-bottom: 0.5px solid #093220;
        padding-bottom: 0.5rem;

        span {
          font-weight: 400;
        }
      }

      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }

  ul {
    margin-bottom: 3rem;

    @media #{$non-mobile} {
      margin-bottom: 5rem;
    }

    li {
      padding: 0 0 1rem 2rem;
      text-indent: -2rem;

      &::before {
        content: "• ";
        color: #e19228;
        padding-right: 1rem;
      }

      li {
        padding-top: 1rem;

        &::before {
          content: none;
        }
      }
    }
  }

  .footnote {
    font-size: 1rem;
    padding-bottom: .3rem;
    text-indent: -1rem;
    padding-left: 1rem;

    @media #{$non-mobile} {
      font-size: 1.4rem;
    }

    sup {
      font-size: 50%;
    }
  }

  .no-textindent {
    text-indent: 0;
    padding-left: 0;
    margin-bottom: .8rem;

    @media #{$non-mobile} {
      margin-bottom: 1rem;
    }
  }
}
