﻿/* Adult and Default */
.complete-freedom {
  margin-bottom: 2rem;

  .about-pediatrics-page & {
    @media screen and (min-width: 768px) {
      margin-bottom: 5rem;
    }
  }
  //padding: 0 2rem;

  .header-set {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      max-width: 97rem;
      margin: 0 auto;
    }

    h2:after {
      @media screen and (min-width: 768px) {
        bottom: 2rem;
      }
    }
  }

  h2 {
    margin-bottom: 4rem;
    text-align: center;

    .header-icon {
      display: block;
      margin: 2rem auto;
    }
    /*&:after {
            bottom: -2rem;
        }*/

    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  section:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  h3 {
    color: #58595B;
    margin-bottom: 1.5rem;
    text-align: center;
    font-size: 1.6rem;
    /*@media screen and (min-width: 768px) {
            font-size: 2.2rem;
        }*/
  }

  h4 {
    color: #00402C;
    font-size: 1.6rem;
  }

  p {
    margin-bottom: 1rem;
  }

  li {
    margin-bottom: 1rem;

    &::before {
      color: #E19228;
      content: "•";
      margin: 0 0.5rem 0 -1.25rem;
    }
  }

  .emphasis-green {
    color: #008A5E;
  }

  .blue-container {
    background-color: #E8F6FC;
    border-radius: 2rem;
    max-width: 95rem;
    margin: 2rem auto;
    padding: 2rem;

    li {
      margin-left: 1.25rem;
    }
  }

  .bullets {
    max-width: 87rem;
    margin: 4.5rem auto;
    padding: 0 2rem;
  }

  .statistic-left {
    display: inline-block;
    margin: 2rem 0;

    .value {
      border-right: 1px solid #C7C7C7;
      color: #00794B;
      float: left;
      font-size: 5.5rem;
      font-weight: bold;
      padding-right: 2rem;
      text-align: center;
      text-transform: uppercase;
      width: 35%;
      position: relative;

      span:first-child {
        font-size: 65%;
        position: relative;
        top: -10px;
      }
    }

    .value-detail {
      font-size: 1rem;
      position: relative;
      left: -2rem;
    }

    .label {
      font-size: 1.8rem;
      font-weight: bold;
      float: left;
      margin: 0.5rem 0;
      padding-left: 2rem;
      width: 65%;
    }
  }

  .statistic-top {
    .value {
      border-bottom: 1px solid #C7C7C7;
      color: #00794B;
      font-size: 5.5rem;
      font-weight: bold;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      text-align: center;
      text-transform: uppercase;
    }

    .value-detail {
      vertical-align: middle;
    }

    .value-separator {
      display: inline-block;
      font-size: 2rem;
      height: 2rem;
      vertical-align: middle;
    }

    ul {
      padding: 0 1rem;
    }
  }



  .footnotes {
    font-size: 1rem;

    .daggers {
      margin: 0 0.35rem 0 -1rem;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 0rem 6rem;

    h2 {
      display: inline-block;
      text-align: left;

      .header-icon {
        display: inline;
        margin-right: 1rem;
        vertical-align: middle;
      }

      .header-text {
        display: inline-block;
        margin-left: 1rem;
        vertical-align: middle;
        width: calc(100% - 156px - 3rem);
      }
    }

    h3 {
      margin-bottom: 2rem;
      font-size: 2.2rem;
    }

    .blue-container {
      padding: 2rem 10rem;
    }

    .footnotes {
      font-size: 1.4rem;
    }

    h4 {
      margin-left: 6rem;
    }

    .statistic-left {
      margin-bottom: 0;
      margin-left: 6rem;

      .value {
        width: auto;
      }

      .label {
        width: auto;
        font-size: 2.5rem;
      }

      .emphasis-green {
        display: block;
      }
    }
  }

  #gattex_volume_svg,
  #gattex_calTime_svg,
  #gattex_freedom_svg {
    width: 137px;
    height: 137px;
  }
}
    /* Pediatrics */
#pediatrics-complete-freedom.complete-freedom {
  h2 {
    display: block;
    margin: 0 auto 4rem auto;
  }

  h3 {
    margin-bottom: 4rem;
  }

  .icon-box {
    margin: 0;
    padding: 9.125rem 2rem 2rem;
    position: relative;

    .icon-box-img {
      transform: translateX(-50%);
      left: 50%;
      width: 13.7rem;
      height: 13.7rem;
      top: -1rem;
      position: absolute;

      @media screen and (min-width: 768px) {
        margin-top: 1rem;
      }
    }



    p {
      background-color: $ltGreen;
      border-radius: 1rem;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.5;
      padding: 5rem 2rem 1.5rem;
      text-align: center;


      .emphasis-green {
        display: block;
      }
    }

    sup {
      font-size: 1rem;
    }
  }

  .footnotes {
    margin-left: 0rem;
  }

  .value {
    width: 8rem;
  }

  h4 {
    font-size: 1.4rem;
  }

  .statistic-left {
    margin-top: .5rem;

    @media screen and (min-width: 768px) {
      margin-bottom: 2rem;
    }

    .label {
      width: 75%;
      left: 0;

      @media screen and (min-width: 768px) {
        width: auto;
      }
    }

    .value-detail {
      left: 0;
    }
  }

  .bullets {
    margin: 0 0 1rem 0;
  }

  @media screen and (min-width: 768px) {
    h2 {
      text-align: center;
      //margin-bottom: 0;
      max-width: 74rem;
      /*&:after {
                bottom: -2rem;
            }*/
    }

    h3 {
      padding-top: 2rem;
    }

    h4 {
      font-size: 1.6rem;
    }

    .icon-box {
      /*margin: 2rem 0;
            padding: 2rem;*/
      padding: 0;
      max-width: 75rem;
      margin: 4rem auto 0 auto;

      .icon-box-img {
        left: auto;
        top: -2rem;
      }

      p {
        /*font-size: 2.2rem;
                padding: 2rem 2rem 2rem 18rem;
                text-align: left;*/
        padding: 3rem 5rem 1rem 13rem;
        font-size: 2.2rem;
        line-height: 2.6rem;
        min-height: 11.3rem;
        margin-bottom: 0;
        text-align: left;

        .emphasis-green {
          display: inline;
        }
      }

      sup {
        font-size: 1.5rem;
      }
    }

    .statistic-left {
      //margin-bottom: 2rem;


      .label {
        font-size: 2.3rem;
      }

      .value-detail {
        left: 0;
      }
    }

    .bullets {
      margin: 0;
    }

    .indent-section {
      max-width: 63.5rem;
      margin: 5rem auto 0 auto;
    }
  }
  /*@media screen and (min-width: 1200px) {
        .indent-section {
            margin: 0 156px;
        }
    }*/
}
