﻿.photo-callout {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  transition: opacity .5s ease-in;
  opacity: 0;

  @media (min-width: 641px) {
    height: 63rem;
  }

  &.peggy {
    opacity: 1;

    .avif & {
      background-image: url(/dist/images/photo-callout-peggy.avif);
    }

    .webp & {
      background-image: url(/dist/images/photo-callout-peggy.webp);
    }

    &.lazy {
      background-image: none;
      opacity: 0;
    }
  }

  &.peggy2 {
    opacity: 1;

    .avif & {
      background-image: url(/dist/images/photo-callout-peggy2.avif);
    }

    .webp & {
      background-image: url(/dist/images/photo-callout-peggy2.webp);
    }

    &.lazy {
      background-image: none;
      opacity: 0;
    }
  }

  &.betsy {
    opacity: 1;

    .avif & {
      background-image: url(/dist/images/photo-callout-betsy.avif);
    }

    .webp & {
      background-image: url(/dist/images/photo-callout-betsy.webp);
    }

    &.lazy {
      background-image: none;
      opacity: 0;
    }
  }

  &.lynda {
    opacity: 1;

    .avif & {
      background-image: url(/dist/images/photo-callout-lynda.avif);
    }

    .webp & {
      background-image: url(/dist/images/photo-callout-lynda.webp);
    }

    &.lazy {
      background-image: none;
      opacity: 0;
    }
  }

  &.kat {
    opacity: 1;

    .avif & {
      background-image: url(/dist/images/photo-callout-kat.avif);
    }

    .webp & {
      background-image: url(/dist/images/photo-callout-kat.webp);
    }


    &.lazy {
      background-image: none;
      opacity: 0;
    }

    @media (min-width: 641px) {
      .wrapper .copy {
        left: 6%;
      }
    }
  }

  &.kelsey {
    opacity: 1;

    .avif & {
      background-image: url(/dist/images/photo-callout-kelsey.avif);
    }

    .webp & {
      background-image: url(/dist/images/photo-callout-kelsey.webp);
    }

    &.lazy {
      background-image: none;
      opacity: 0;
    }

    @media (min-width: 641px) {
      .wrapper .copy {
        align-items: flex-end;

        .quote {
          left: auto;
          right: 15%;
        }

        .author {
          left: auto;
          right: 15%;
          width: 43rem;
        }
      }
    }
  }

  &.actors {
    opacity: 1;

    .avif & {
      background-image: url(/dist/images/photo-callout-actors.avif);
    }

    .webp & {
      background-image: url(/dist/images/photo-callout-actors.webp);
    }

    &.lazy {
      background-image: none;
      opacity: 0;
    }
  }

  &.actors2 {
    opacity: 1;

    .avif & {
      background-image: url(/dist/images/photo-callout-actors2.avif);
    }

    .webp & {
      background-image: url(/dist/images/photo-callout-actors2.webp);
    }


    &.lazy {
      background-image: none;
      opacity: 0;
    }
  }

  .wrapper {
    height: 100%;
    display: block;
    -webkit-box-align: center;
    align-items: center;
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    position: relative;

    img {
      display: block;

      @media (min-width: 641px) {
        display: none;
      }
    }

    .copy {
      position: relative;
      left: auto;
      width: 100%;
      padding: 4rem 2.5rem;
      max-width: 100%;
      background-color: #E5F0ED;

      @media (min-width: 641px) {
        background-color: transparent;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        max-width: 95rem;
        margin: 0 auto;
      }

      &.no-quote {
        position: absolute;
        background-color: transparent;
        top: 0;
        padding-top: 12rem;

        @media (min-width: 641px) {
          padding-top: 40rem;
          position: relative;
        }

        .author {
          color: #FFFFFF;
          text-align: left;
          font-size: .8rem;

          @media (min-width: 641px) {
            font-size: initial;
          }
        }
      }

      .quote {
        display: block;
        color: #58595B;
        text-align: center;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 700;
        position: relative;
        margin: 0 0 2rem 0;

        @media (min-width: 641px) {
          display: block;
          color: #FFFFFF;
          font-size: 2.8rem;
          line-height: 3.4rem;
          font-weight: 700;
          max-width: 43rem;
          text-align: left;
        }

        span {
          &:first-child {
            position: relative;
            left: 0.3rem;

            @media (min-width: 641px) {
              position: absolute;
              left: -1rem;
            }
          }
        }
      }

      .author {
        color: #58595B;
        text-align: center;
        font-size: 1.6rem;
        line-height: 2rem;
        text-transform: uppercase;
        font-weight: 700;
        position: relative;

        @media (min-width: 641px) {
          color: #FFFFFF;
          font-size: 1.4rem;
          line-height: 1.8rem;
          text-align: left;
          max-width: 43rem;
        }
      }
    }
  }
}
