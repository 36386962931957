﻿#pediatrics-accordion-first {
    .copy {
        padding-left: 2.2rem;
        padding-right: 2.2rem;

        @media (min-width: 769px) {
            padding: initial;
        }

        p {
            max-width: 77.2rem;
            text-align: center;

            &:first-child {
                margin: 2.4rem auto 2.5rem auto;

                @media (min-width: 769px) {
                    margin: 6rem auto 2.5rem auto;
                }
            }

            &:last-child {
                margin: 0 auto 2.5rem auto;

                @media (min-width: 769px) {
                    margin: 0 auto 7rem auto;
                }
            }
        }

        sup {
            font-size: 65%;
        }
    }
}
