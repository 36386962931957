@charset "UTF-8";
/*========== Media Queries ==========*/
/*========== Colors ==========*/
/*========== Fonts ==========*/
.accordion {
  width: 100%;
  margin: 0 auto 0px auto;
  max-width: 950px;
  border: .1rem solid #E2DCDC; }
  .accordion input {
    position: absolute;
    opacity: 0;
    right: 0; }
    .accordion input:checked ~ .copy {
      max-height: 280rem; }
    .accordion input:checked ~ label {
      background-image: url(/dist/images/icon-close-drawer.svg); }
  .accordion label {
    background-color: #2cb79f;
    color: #fff;
    padding: 1rem 3rem 1rem 1rem;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.2rem;
    cursor: pointer;
    display: flex;
    background-image: url(/dist/images/icon-minus-drawer.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 1rem) center;
    background-size: 2.7rem; }
    @media (min-with: 768px) {
      .accordion label {
        line-height: 2.8rem; } }
  .accordion .copy {
    max-height: 0;
    overflow: hidden;
    padding: 0 3.5rem 0 3.5rem;
    text-align: left;
    transition: all 0.3s ease;
    border-width: 0; }

#pediatrics-accordion-first .copy {
  padding-left: 2.2rem;
  padding-right: 2.2rem; }
  @media (min-width: 769px) {
    #pediatrics-accordion-first .copy {
      padding: initial; } }
  #pediatrics-accordion-first .copy p {
    max-width: 77.2rem;
    text-align: center; }
    #pediatrics-accordion-first .copy p:first-child {
      margin: 2.4rem auto 2.5rem auto; }
      @media (min-width: 769px) {
        #pediatrics-accordion-first .copy p:first-child {
          margin: 6rem auto 2.5rem auto; } }
    #pediatrics-accordion-first .copy p:last-child {
      margin: 0 auto 2.5rem auto; }
      @media (min-width: 769px) {
        #pediatrics-accordion-first .copy p:last-child {
          margin: 0 auto 7rem auto; } }
  #pediatrics-accordion-first .copy sup {
    font-size: 65%; }

#pediatrics-accordion-second {
  padding-top: 3rem; }
  @media only screen and (min-width: 768px) {
    #pediatrics-accordion-second {
      padding-top: 10rem; } }
  #pediatrics-accordion-second sup {
    font-size: 65%; }
  #pediatrics-accordion-second h3 {
    color: #008a5e;
    margin-bottom: 3rem;
    border-bottom: 1px solid #707070;
    padding-bottom: 1rem; }
    @media screen and (min-width: 764px) {
      #pediatrics-accordion-second h3 {
        max-width: 75.4rem;
        margin: 3rem auto; } }
  #pediatrics-accordion-second h4 {
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 3rem;
    font-size: 1.8rem; }
    @media screen and (min-width: 764px) {
      #pediatrics-accordion-second h4 {
        max-width: 75.4rem;
        margin: 5rem auto 3rem auto;
        font-size: 2.2rem;
        line-height: 2.6rem; } }
  @media screen and (min-width: 764px) {
    #pediatrics-accordion-second .copy > ul {
      max-width: 75.4rem;
      margin: 0 auto; } }
  #pediatrics-accordion-second .subcopy {
    font-weight: 700;
    margin-bottom: 3rem; }
    @media screen and (min-width: 764px) {
      #pediatrics-accordion-second .subcopy {
        max-width: 75.4rem;
        margin: 0 auto 3rem auto;
        font-size: 1.6rem;
        line-height: 2rem; } }
  #pediatrics-accordion-second ul li {
    padding: 0 0 1rem 2rem;
    text-indent: -1.8rem; }
    #pediatrics-accordion-second ul li::before {
      content: "• ";
      color: #e19228;
      padding-right: 1rem; }
    #pediatrics-accordion-second ul li li {
      padding-top: 1rem;
      text-indent: -1.3rem; }
      #pediatrics-accordion-second ul li li::before {
        content: none; }
  #pediatrics-accordion-second .footnote {
    font-size: 10px;
    margin-bottom: 1rem; }
    @media only screen and (min-width: 768px) {
      #pediatrics-accordion-second .footnote {
        font-size: 14px; } }
  #pediatrics-accordion-second .accordion .copy {
    padding: 0 2rem; }
    @media only screen and (min-width: 768px) {
      #pediatrics-accordion-second .accordion .copy {
        padding: 0 1.5rem 0; } }
  #pediatrics-accordion-second .study-6-month {
    background-image: url(/dist/images/steps-green-pediatrics-mobile.webp);
    background-repeat: no-repeat;
    width: 30.9rem;
    height: 66.7rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    margin: 0 auto 3rem;
    transition: opacity .5s ease-in; }
    #pediatrics-accordion-second .study-6-month.lazy {
      background-image: none;
      opacity: 0; }
    @media only screen and (min-width: 768px) {
      #pediatrics-accordion-second .study-6-month {
        background-image: url(/dist/images/steps-green-pediatrics-desktop.webp);
        background-repeat: no-repeat;
        width: 80rem;
        height: 27.5rem;
        flex-direction: row;
        margin: 0 auto 5rem;
        position: relative;
        left: 2rem; } }
  #pediatrics-accordion-second .month-sec div:nth-child(1) {
    position: relative;
    transform: rotate(0);
    left: 11rem;
    top: 1.4rem;
    font-weight: 700; }
    @media only screen and (min-width: 768px) {
      #pediatrics-accordion-second .month-sec div:nth-child(1) {
        transform: rotate(-90deg);
        left: 10.5rem; } }
  #pediatrics-accordion-second .month-sec div .heading {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 1.2rem; }
    @media only screen and (min-width: 768px) {
      #pediatrics-accordion-second .month-sec div .heading {
        font-size: 1.8rem; } }
  #pediatrics-accordion-second .month-sec div span {
    display: block;
    margin-bottom: 1rem; }
  #pediatrics-accordion-second .month-sec div.first-col {
    margin-top: 50px; }
    @media only screen and (min-width: 768px) {
      #pediatrics-accordion-second .month-sec div.first-col {
        margin-left: -3rem; } }
    #pediatrics-accordion-second .month-sec div.first-col span {
      font-size: 1.2rem;
      font-weight: 700; }
      @media only screen and (min-width: 768px) {
        #pediatrics-accordion-second .month-sec div.first-col span {
          font-size: 1.4rem; } }
    #pediatrics-accordion-second .month-sec div.first-col .heading {
      margin-bottom: .5rem; }
    #pediatrics-accordion-second .month-sec div.first-col .border {
      max-width: 20rem;
      margin: 0 auto;
      text-align: left;
      font-weight: 500; }
      @media only screen and (min-width: 768px) {
        #pediatrics-accordion-second .month-sec div.first-col .border {
          max-width: 100%; } }
  #pediatrics-accordion-second .month-sec div.second-col {
    margin: 70px auto 0; }
    @media only screen and (min-width: 768px) {
      #pediatrics-accordion-second .month-sec div.second-col {
        margin: auto; }
        #pediatrics-accordion-second .month-sec div.second-col .heading {
          margin-bottom: 1rem; } }
  #pediatrics-accordion-second .month-sec div .border {
    border-top: 1px solid #fff;
    padding-top: .5rem;
    max-width: 12rem;
    margin: 0 auto; }
  #pediatrics-accordion-second .month-sec div p {
    font-weight: 700; }
  #pediatrics-accordion-second .month-sec div ul {
    margin: 1rem 0;
    padding: 0 2rem 0 5rem; }
    @media only screen and (min-width: 768px) {
      #pediatrics-accordion-second .month-sec div ul {
        padding: 0 2rem 0 2rem; } }
    #pediatrics-accordion-second .month-sec div ul li {
      padding: 0;
      text-indent: -1.2rem;
      text-align: left; }
      #pediatrics-accordion-second .month-sec div ul li::before {
        content: '—';
        color: #FFFFFF;
        padding-right: 0; }
  #pediatrics-accordion-second .month-sec .col {
    text-align: center;
    margin: 45px auto 0; }
    @media only screen and (min-width: 768px) {
      #pediatrics-accordion-second .month-sec .col {
        margin: auto; } }

#pediatrics-accordion-third {
  padding-top: 3rem;
  padding-bottom: 3rem; }
  @media only screen and (min-width: 768px) {
    #pediatrics-accordion-third {
      padding-top: 5rem;
      padding-bottom: 5rem; } }
  #pediatrics-accordion-third .table-container {
    padding: 1.5rem 0; }
    @media only screen and (min-width: 768px) {
      #pediatrics-accordion-third .table-container {
        padding: 5rem 0;
        max-width: 75.5rem;
        margin: 0 auto; } }
    #pediatrics-accordion-third .table-container h4 {
      text-align: center;
      margin-bottom: 1rem;
      font-size: 1.8rem; }
      @media only screen and (min-width: 768px) {
        #pediatrics-accordion-third .table-container h4 {
          font-size: 2.2rem;
          margin-bottom: 3rem; } }
    #pediatrics-accordion-third .table-container .table {
      width: 100%;
      border-collapse: collapse;
      color: #254331;
      margin-bottom: 2rem;
      font-size: .7rem; }
      @media (min-width: 769px) {
        #pediatrics-accordion-third .table-container .table {
          font-size: inherit;
          margin-bottom: 5rem; } }
      #pediatrics-accordion-third .table-container .table thead tr th {
        font-weight: 700;
        border-bottom: 1px solid #c6c5c5;
        padding: 0;
        text-align: center;
        vertical-align: middle;
        font-size: 1.1rem; }
        @media (min-width: 769px) {
          #pediatrics-accordion-third .table-container .table thead tr th {
            font-size: inherit; } }
        #pediatrics-accordion-third .table-container .table thead tr th:first-of-type {
          text-align: left; }
      #pediatrics-accordion-third .table-container .table tbody tr {
        border-bottom: 1px solid #c6c5c5; }
        #pediatrics-accordion-third .table-container .table tbody tr:last-of-type {
          border-bottom: none; }
        #pediatrics-accordion-third .table-container .table tbody tr.heading {
          background-color: #ECF3F0; }
          #pediatrics-accordion-third .table-container .table tbody tr.heading td {
            color: #258961; }
        #pediatrics-accordion-third .table-container .table tbody tr td {
          font-weight: 700;
          padding: 0.3rem 0;
          text-align: center;
          font-size: .9rem; }
          @media (min-width: 769px) {
            #pediatrics-accordion-third .table-container .table tbody tr td {
              font-size: 1.2rem; } }
          #pediatrics-accordion-third .table-container .table tbody tr td:first-of-type {
            text-align: left;
            padding-left: 0.3rem; }
          #pediatrics-accordion-third .table-container .table tbody tr td:last-of-type {
            color: #008A5E; }
          #pediatrics-accordion-third .table-container .table tbody tr td span {
            display: block;
            padding-left: 1.5rem;
            font-style: italic; }
        #pediatrics-accordion-third .table-container .table tbody tr.inset td:first-of-type {
          padding-left: 1.5rem; }
  #pediatrics-accordion-third .blue-sec {
    border-top: 3px solid #2d9ae3;
    border-bottom: 3px solid #2d9ae3;
    padding: 3rem;
    text-align: center;
    margin: 3rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 101.5rem;
    width: 100%; }
    @media (min-width: 768px) {
      #pediatrics-accordion-third .blue-sec {
        padding: 3rem 1rem; } }
    #pediatrics-accordion-third .blue-sec p {
      color: #2d9ae3;
      font-size: 1.6rem;
      font-weight: 500; }
      #pediatrics-accordion-third .blue-sec p strong {
        font-weight: 700; }
      @media (min-width: 768px) {
        #pediatrics-accordion-third .blue-sec p {
          font-size: 2.4rem;
          max-width: none; } }
    #pediatrics-accordion-third .blue-sec a {
      color: #2d9ae3; }
  #pediatrics-accordion-third .accordion .copy {
    padding: 0 1rem; }

#gattex-moa-video {
  margin: 3rem 0 6rem 0; }
  @media (min-width: 768px) {
    #gattex-moa-video {
      margin-top: 6rem; } }
  #gattex-moa-video h3 {
    color: #58595B;
    text-align: center;
    margin-bottom: 2rem; }
  #gattex-moa-video p {
    text-align: center;
    margin-bottom: 2rem; }
    @media (min-width: 768px) {
      #gattex-moa-video p {
        margin-top: 2rem;
        margin-bottom: 4rem; } }
  #gattex-moa-video #brightcove-player {
    margin: 0 auto;
    max-width: 91.6rem; }
    #gattex-moa-video #brightcove-player .video-js {
      width: 100%;
      height: 21.1rem;
      overflow: hidden;
      background-color: transparent !important; }
      @media (min-width: 768px) {
        #gattex-moa-video #brightcove-player .video-js {
          height: 51.5rem; } }
    #gattex-moa-video #brightcove-player .bc-player-SykGTiz2b_default:hover .vjs-big-play-button {
      background-color: #008a5e; }
    #gattex-moa-video #brightcove-player .vjs-poster {
      background-color: transparent; }

.photo-callout {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  transition: opacity .5s ease-in;
  opacity: 0; }
  @media (min-width: 641px) {
    .photo-callout {
      height: 63rem; } }
  .photo-callout.peggy {
    opacity: 1; }
    .avif .photo-callout.peggy {
      background-image: url(/dist/images/photo-callout-peggy.avif); }
    .webp .photo-callout.peggy {
      background-image: url(/dist/images/photo-callout-peggy.webp); }
    .photo-callout.peggy.lazy {
      background-image: none;
      opacity: 0; }
  .photo-callout.peggy2 {
    opacity: 1; }
    .avif .photo-callout.peggy2 {
      background-image: url(/dist/images/photo-callout-peggy2.avif); }
    .webp .photo-callout.peggy2 {
      background-image: url(/dist/images/photo-callout-peggy2.webp); }
    .photo-callout.peggy2.lazy {
      background-image: none;
      opacity: 0; }
  .photo-callout.betsy {
    opacity: 1; }
    .avif .photo-callout.betsy {
      background-image: url(/dist/images/photo-callout-betsy.avif); }
    .webp .photo-callout.betsy {
      background-image: url(/dist/images/photo-callout-betsy.webp); }
    .photo-callout.betsy.lazy {
      background-image: none;
      opacity: 0; }
  .photo-callout.lynda {
    opacity: 1; }
    .avif .photo-callout.lynda {
      background-image: url(/dist/images/photo-callout-lynda.avif); }
    .webp .photo-callout.lynda {
      background-image: url(/dist/images/photo-callout-lynda.webp); }
    .photo-callout.lynda.lazy {
      background-image: none;
      opacity: 0; }
  .photo-callout.kat {
    opacity: 1; }
    .avif .photo-callout.kat {
      background-image: url(/dist/images/photo-callout-kat.avif); }
    .webp .photo-callout.kat {
      background-image: url(/dist/images/photo-callout-kat.webp); }
    .photo-callout.kat.lazy {
      background-image: none;
      opacity: 0; }
    @media (min-width: 641px) {
      .photo-callout.kat .wrapper .copy {
        left: 6%; } }
  .photo-callout.kelsey {
    opacity: 1; }
    .avif .photo-callout.kelsey {
      background-image: url(/dist/images/photo-callout-kelsey.avif); }
    .webp .photo-callout.kelsey {
      background-image: url(/dist/images/photo-callout-kelsey.webp); }
    .photo-callout.kelsey.lazy {
      background-image: none;
      opacity: 0; }
    @media (min-width: 641px) {
      .photo-callout.kelsey .wrapper .copy {
        align-items: flex-end; }
        .photo-callout.kelsey .wrapper .copy .quote {
          left: auto;
          right: 15%; }
        .photo-callout.kelsey .wrapper .copy .author {
          left: auto;
          right: 15%;
          width: 43rem; } }
  .photo-callout.actors {
    opacity: 1; }
    .avif .photo-callout.actors {
      background-image: url(/dist/images/photo-callout-actors.avif); }
    .webp .photo-callout.actors {
      background-image: url(/dist/images/photo-callout-actors.webp); }
    .photo-callout.actors.lazy {
      background-image: none;
      opacity: 0; }
  .photo-callout.actors2 {
    opacity: 1; }
    .avif .photo-callout.actors2 {
      background-image: url(/dist/images/photo-callout-actors2.avif); }
    .webp .photo-callout.actors2 {
      background-image: url(/dist/images/photo-callout-actors2.webp); }
    .photo-callout.actors2.lazy {
      background-image: none;
      opacity: 0; }
  .photo-callout .wrapper {
    height: 100%;
    display: block;
    -webkit-box-align: center;
    align-items: center;
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    position: relative; }
    .photo-callout .wrapper img {
      display: block; }
      @media (min-width: 641px) {
        .photo-callout .wrapper img {
          display: none; } }
    .photo-callout .wrapper .copy {
      position: relative;
      left: auto;
      width: 100%;
      padding: 4rem 2.5rem;
      max-width: 100%;
      background-color: #E5F0ED; }
      @media (min-width: 641px) {
        .photo-callout .wrapper .copy {
          background-color: transparent;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          max-width: 95rem;
          margin: 0 auto; } }
      .photo-callout .wrapper .copy.no-quote {
        position: absolute;
        background-color: transparent;
        top: 0;
        padding-top: 12rem; }
        @media (min-width: 641px) {
          .photo-callout .wrapper .copy.no-quote {
            padding-top: 40rem;
            position: relative; } }
        .photo-callout .wrapper .copy.no-quote .author {
          color: #FFFFFF;
          text-align: left;
          font-size: .8rem; }
          @media (min-width: 641px) {
            .photo-callout .wrapper .copy.no-quote .author {
              font-size: initial; } }
      .photo-callout .wrapper .copy .quote {
        display: block;
        color: #58595B;
        text-align: center;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 700;
        position: relative;
        margin: 0 0 2rem 0; }
        @media (min-width: 641px) {
          .photo-callout .wrapper .copy .quote {
            display: block;
            color: #FFFFFF;
            font-size: 2.8rem;
            line-height: 3.4rem;
            font-weight: 700;
            max-width: 43rem;
            text-align: left; } }
        .photo-callout .wrapper .copy .quote span:first-child {
          position: relative;
          left: 0.3rem; }
          @media (min-width: 641px) {
            .photo-callout .wrapper .copy .quote span:first-child {
              position: absolute;
              left: -1rem; } }
      .photo-callout .wrapper .copy .author {
        color: #58595B;
        text-align: center;
        font-size: 1.6rem;
        line-height: 2rem;
        text-transform: uppercase;
        font-weight: 700;
        position: relative; }
        @media (min-width: 641px) {
          .photo-callout .wrapper .copy .author {
            color: #FFFFFF;
            font-size: 1.4rem;
            line-height: 1.8rem;
            text-align: left;
            max-width: 43rem; } }

/* Adult and Default */
.reduction-ps {
  margin-top: 1rem;
  padding: 0 2rem 2rem 2rem; }
  @media screen and (min-width: 1024px) {
    .reduction-ps {
      margin-bottom: 4rem; } }
  .reduction-ps .container {
    padding: 0 .5rem; }
  .reduction-ps h2 {
    margin: 4rem 0;
    text-align: center; }
    .about-pediatrics-page .reduction-ps h2 {
      margin-bottom: 4rem; }
      @media screen and (min-width: 768px) {
        .about-pediatrics-page .reduction-ps h2 {
          max-width: 82rem;
          margin: 0 auto 4rem auto; } }
      .about-pediatrics-page .reduction-ps h2:after {
        bottom: -2.5rem; }
    .reduction-ps h2 .header-icon {
      display: block;
      margin: 2rem auto; }
  .reduction-ps section:not(:last-child) {
    margin-bottom: 2.5rem; }
  .reduction-ps h3 {
    color: #58595B;
    margin-bottom: 1.5rem;
    text-align: center; }
    .reduction-ps h3.after-30-months {
      font-size: 1.6rem;
      max-width: 81.4rem; }
      @media screen and (min-width: 768px) {
        .reduction-ps h3.after-30-months {
          font-size: 2.2rem; } }
    .about-pediatrics-page .reduction-ps h3 {
      margin-top: 0;
      font-size: 1.6rem; }
      @media screen and (min-width: 768px) {
        .about-pediatrics-page .reduction-ps h3 {
          font-size: 2.2rem;
          text-align: center; } }
  .reduction-ps h4 {
    color: #00402C;
    font-size: 1.7rem; }
  .reduction-ps p {
    margin-bottom: 1rem; }
    .reduction-ps p i {
      font-style: italic; }
  .reduction-ps li {
    margin-bottom: 1rem; }
    .reduction-ps li::before {
      color: #E19228;
      content: "•";
      margin: 0 0.5rem 0 -1.25rem; }
  .reduction-ps .emphasis-green {
    color: #008A5E; }
  .reduction-ps .bar-chart {
    border-bottom: 5px solid #009DDC;
    margin: 2rem 0 6rem;
    position: relative; }
    .reduction-ps .bar-chart .bar-chart-total {
      color: #009DDC;
      font-weight: bold;
      font-size: 3rem;
      position: absolute;
      right: 0;
      bottom: -5rem;
      text-align: right; }
    .reduction-ps .bar-chart .value {
      display: inline;
      font-size: 2.5rem;
      font-weight: bold;
      margin-left: 1rem;
      vertical-align: middle;
      position: relative; }
      @media screen and (min-width: 768px) {
        .reduction-ps .bar-chart .value {
          display: flex;
          flex-direction: column; } }
      .reduction-ps .bar-chart .value span:first-child {
        position: absolute;
        font-size: 1.2rem;
        left: 2.7rem;
        top: 0.5rem; }
      .reduction-ps .bar-chart .value .value-detail {
        font-size: 1rem;
        vertical-align: middle;
        position: relative;
        left: 1rem;
        top: -0.5rem; }
        @media screen and (min-width: 768px) {
          .reduction-ps .bar-chart .value .value-detail {
            font-size: 1.1rem;
            left: .5rem;
            top: auto; } }
    .reduction-ps .bar-chart .bar-green {
      background-color: #00794B;
      color: #fff;
      display: inline-block;
      font-weight: bold;
      margin-bottom: 0.5rem;
      padding: 1rem; }
      @media screen and (min-width: 768px) {
        .reduction-ps .bar-chart .bar-green {
          padding: 2rem 1rem;
          max-height: 6.6rem; } }
      .reduction-ps .bar-chart .bar-green + div {
        color: #00794B; }
    .reduction-ps .bar-chart .bar-gray {
      background-color: #818386;
      color: #fff;
      display: inline-block;
      font-weight: bold;
      margin-bottom: 0.5rem;
      padding: 1rem 1rem; }
      @media screen and (min-width: 768px) {
        .reduction-ps .bar-chart .bar-gray {
          padding: 2rem 1rem;
          max-height: 6.6rem; } }
      .reduction-ps .bar-chart .bar-gray + div {
        color: #818386; }
  .reduction-ps .statistic-left {
    display: inline-block;
    margin: 2rem 0; }
    .about-pediatrics-page .reduction-ps .statistic-left {
      display: flex; }
    .reduction-ps .statistic-left .value {
      border-right: 1px solid #C7C7C7;
      color: #00794B;
      float: left;
      font-size: 5.5rem;
      font-weight: bold;
      padding-right: 1rem;
      text-transform: uppercase;
      width: 35%;
      position: relative; }
      @media screen and (min-width: 1024px) {
        .reduction-ps .statistic-left .value {
          padding-right: 4rem;
          margin-left: 0.8rem; } }
      .reduction-ps .statistic-left .value span:first-child {
        position: absolute;
        font-size: 3.2rem;
        left: 6rem;
        top: 0.5rem; }
      .about-pediatrics-page .reduction-ps .statistic-left .value {
        font-size: 4rem;
        text-align: left;
        width: 100%;
        max-width: 8rem; }
        @media screen and (min-width: 768px) {
          .about-pediatrics-page .reduction-ps .statistic-left .value {
            font-size: 6rem;
            max-width: 11rem; } }
        .about-pediatrics-page .reduction-ps .statistic-left .value span {
          font-size: 65%;
          position: relative;
          top: -1rem;
          left: 0; }
          @media screen and (min-width: 768px) {
            .about-pediatrics-page .reduction-ps .statistic-left .value span {
              font-size: 50%;
              top: -2rem;
              left: 0; } }
    .reduction-ps .statistic-left .value-detail {
      font-size: 1rem;
      margin-left: 1.4rem; }
      @media (min-width: 768px) {
        .reduction-ps .statistic-left .value-detail {
          margin-top: -.5rem; } }
    .reduction-ps .statistic-left .label {
      font-size: 1.8rem;
      font-weight: bold;
      float: left;
      margin: 0.5rem 0;
      padding-left: 2rem;
      width: 65%; }
      .about-pediatrics-page .reduction-ps .statistic-left .label {
        font-size: 1.6rem;
        width: 100%;
        padding-left: 1.5rem; }
        @media screen and (min-width: 768px) {
          .about-pediatrics-page .reduction-ps .statistic-left .label {
            font-size: 2.5rem;
            line-height: 3rem; } }
  .reduction-ps .footnotes {
    font-size: 1rem;
    position: relative; }
    @media screen and (min-width: 768px) {
      .reduction-ps .footnotes {
        max-width: 85.8rem;
        margin: 0 auto 5rem auto;
        font-size: 1.4rem;
        line-height: 1.7rem; } }
    .reduction-ps .footnotes .daggers {
      position: absolute;
      top: .3rem;
      left: -.4rem; }
  @media screen and (min-width: 641px) {
    .reduction-ps h2::after {
      bottom: 2.5rem; } }
  @media screen and (min-width: 768px) {
    .reduction-ps {
      padding: 0rem 6rem 2rem; }
      .about-pediatrics-page .reduction-ps {
        padding-top: 10rem; }
      .reduction-ps h2 {
        display: block;
        text-align: left;
        max-width: 94.5rem;
        margin: 1rem auto; }
        .reduction-ps h2 .header-icon {
          display: inline;
          margin-right: 1rem;
          vertical-align: middle; }
        .reduction-ps h2 .header-text {
          display: inline-block;
          margin-left: 1rem;
          vertical-align: middle;
          width: calc(100% - 156px - 3rem); }
      .reduction-ps h3 {
        margin: 0 auto 2rem auto;
        max-width: 75rem; }
        .reduction-ps h3.after-30-months {
          max-width: 81.4rem; }
      .reduction-ps h4 {
        max-width: 86.5rem;
        margin: 0 auto 1rem; }
      .reduction-ps .bar-chart {
        max-width: 86.5rem;
        margin: 2rem auto 6rem auto; }
        .reduction-ps .bar-chart .value {
          font-size: 6rem;
          line-height: 4rem;
          position: relative; }
          .reduction-ps .bar-chart .value span:first-child {
            position: absolute;
            font-size: 3.2rem;
            left: 6.5rem;
            top: -1rem; }
      .reduction-ps .statistic-left {
        margin: 0 auto 2rem auto;
        max-width: 88rem;
        display: flex; }
        .reduction-ps .statistic-left .value {
          width: auto; }
        .reduction-ps .statistic-left .label {
          font-size: 2.3rem;
          width: auto; }
        .reduction-ps .statistic-left .emphasis-green {
          display: block; } }
  .reduction-ps hr {
    border: none;
    height: .1rem;
    background-color: #DC9F53; }
  @media screen and (min-width: 768px) {
    .reduction-ps #bar-chart-gattex, .reduction-ps #bar-chart-placebo {
      display: flex; } }
  .reduction-ps #bar-chart-placebo + p {
    font-size: 1rem; }
    @media screen and (min-width: 641px) {
      .reduction-ps #bar-chart-placebo + p {
        font-size: 1.4rem; } }
  .reduction-ps #gattex_volume_svg,
  .reduction-ps #gattex_calTime_svg,
  .reduction-ps #gattex_freedom_svg {
    width: 137px;
    height: 137px; }

/* Pediatrics */
#pediatrics-reduction-ps.reduction-ps .icon-box {
  margin: 0;
  padding: 9.125rem 2rem 2rem;
  position: relative; }
  .about-pediatrics-page #pediatrics-reduction-ps.reduction-ps .icon-box {
    padding-left: 0;
    padding-right: 0;
    margin-top: 3rem; }
    @media screen and (min-width: 768px) {
      .about-pediatrics-page #pediatrics-reduction-ps.reduction-ps .icon-box {
        padding: 0;
        max-width: 75rem;
        margin: 4rem auto 0 auto; } }
  #pediatrics-reduction-ps.reduction-ps .icon-box .icon-box-img {
    position: absolute;
    top: 0; }
    .about-pediatrics-page #pediatrics-reduction-ps.reduction-ps .icon-box .icon-box-img {
      transform: translateX(-50%);
      left: 50%;
      width: 13.7rem;
      height: 13.7rem; }
      @media screen and (min-width: 768px) {
        .about-pediatrics-page #pediatrics-reduction-ps.reduction-ps .icon-box .icon-box-img {
          left: auto;
          top: -1rem; } }
  #pediatrics-reduction-ps.reduction-ps .icon-box p {
    background-color: #E8F6FC;
    border-radius: 2rem;
    font-size: 1.9rem;
    font-weight: bold;
    line-height: 1.5;
    padding: 8rem 2rem 2rem;
    text-align: center; }
    .about-pediatrics-page #pediatrics-reduction-ps.reduction-ps .icon-box p {
      background-color: #E3F0ED;
      font-size: 1.4rem;
      line-height: 1.8rem;
      padding: 5.5rem 3rem 1.5rem;
      border-radius: 1rem; }
      @media screen and (min-width: 768px) {
        .about-pediatrics-page #pediatrics-reduction-ps.reduction-ps .icon-box p {
          padding: 3rem 5rem 1rem 13rem;
          font-size: 2.2rem;
          line-height: 2.6rem;
          min-height: 11.3rem;
          margin-bottom: 0;
          text-align: left; } }
    #pediatrics-reduction-ps.reduction-ps .icon-box p .emphasis-green {
      display: block; }
      @media screen and (min-width: 768px) {
        .about-pediatrics-page #pediatrics-reduction-ps.reduction-ps .icon-box p .emphasis-green {
          display: inline; } }
  #pediatrics-reduction-ps.reduction-ps .icon-box sup {
    font-size: 1rem; }

#pediatrics-reduction-ps.reduction-ps .footnotes {
  margin-left: 0rem; }

@media screen and (min-width: 768px) {
  #pediatrics-reduction-ps.reduction-ps h2 {
    text-align: center; } }

@media screen and (min-width: 1200px) {
  #pediatrics-reduction-ps.reduction-ps .indent-section {
    margin: 0 156px; } }

@media screen and (min-width: 768px) {
  .about-pediatrics-page #pediatrics-reduction-ps.reduction-ps .indent-section {
    max-width: 63.5rem;
    margin: 5rem auto 6rem auto; } }

.about-pediatrics-page #pediatrics-reduction-ps.reduction-ps .indent-section .statistic-left .value .value-detail {
  left: 0.3rem;
  position: relative; }

/* Adult and Default */
.complete-freedom {
  margin-bottom: 2rem; }
  @media screen and (min-width: 768px) {
    .about-pediatrics-page .complete-freedom {
      margin-bottom: 5rem; } }
  .complete-freedom .header-set {
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media screen and (min-width: 768px) {
      .complete-freedom .header-set {
        flex-direction: row;
        max-width: 97rem;
        margin: 0 auto; } }
    @media screen and (min-width: 768px) {
      .complete-freedom .header-set h2:after {
        bottom: 2rem; } }
  .complete-freedom h2 {
    margin-bottom: 4rem;
    text-align: center;
    /*&:after {
            bottom: -2rem;
        }*/ }
    .complete-freedom h2 .header-icon {
      display: block;
      margin: 2rem auto; }
    @media screen and (min-width: 768px) {
      .complete-freedom h2 {
        margin-bottom: 0; } }
  .complete-freedom section:not(:last-child) {
    margin-bottom: 2.5rem; }
  .complete-freedom h3 {
    color: #58595B;
    margin-bottom: 1.5rem;
    text-align: center;
    font-size: 1.6rem;
    /*@media screen and (min-width: 768px) {
            font-size: 2.2rem;
        }*/ }
  .complete-freedom h4 {
    color: #00402C;
    font-size: 1.6rem; }
  .complete-freedom p {
    margin-bottom: 1rem; }
  .complete-freedom li {
    margin-bottom: 1rem; }
    .complete-freedom li::before {
      color: #E19228;
      content: "•";
      margin: 0 0.5rem 0 -1.25rem; }
  .complete-freedom .emphasis-green {
    color: #008A5E; }
  .complete-freedom .blue-container {
    background-color: #E8F6FC;
    border-radius: 2rem;
    max-width: 95rem;
    margin: 2rem auto;
    padding: 2rem; }
    .complete-freedom .blue-container li {
      margin-left: 1.25rem; }
  .complete-freedom .bullets {
    max-width: 87rem;
    margin: 4.5rem auto;
    padding: 0 2rem; }
  .complete-freedom .statistic-left {
    display: inline-block;
    margin: 2rem 0; }
    .complete-freedom .statistic-left .value {
      border-right: 1px solid #C7C7C7;
      color: #00794B;
      float: left;
      font-size: 5.5rem;
      font-weight: bold;
      padding-right: 2rem;
      text-align: center;
      text-transform: uppercase;
      width: 35%;
      position: relative; }
      .complete-freedom .statistic-left .value span:first-child {
        font-size: 65%;
        position: relative;
        top: -10px; }
    .complete-freedom .statistic-left .value-detail {
      font-size: 1rem;
      position: relative;
      left: -2rem; }
    .complete-freedom .statistic-left .label {
      font-size: 1.8rem;
      font-weight: bold;
      float: left;
      margin: 0.5rem 0;
      padding-left: 2rem;
      width: 65%; }
  .complete-freedom .statistic-top .value {
    border-bottom: 1px solid #C7C7C7;
    color: #00794B;
    font-size: 5.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    text-transform: uppercase; }
  .complete-freedom .statistic-top .value-detail {
    vertical-align: middle; }
  .complete-freedom .statistic-top .value-separator {
    display: inline-block;
    font-size: 2rem;
    height: 2rem;
    vertical-align: middle; }
  .complete-freedom .statistic-top ul {
    padding: 0 1rem; }
  .complete-freedom .footnotes {
    font-size: 1rem; }
    .complete-freedom .footnotes .daggers {
      margin: 0 0.35rem 0 -1rem; }
  @media screen and (min-width: 768px) {
    .complete-freedom {
      padding: 0rem 6rem; }
      .complete-freedom h2 {
        display: inline-block;
        text-align: left; }
        .complete-freedom h2 .header-icon {
          display: inline;
          margin-right: 1rem;
          vertical-align: middle; }
        .complete-freedom h2 .header-text {
          display: inline-block;
          margin-left: 1rem;
          vertical-align: middle;
          width: calc(100% - 156px - 3rem); }
      .complete-freedom h3 {
        margin-bottom: 2rem;
        font-size: 2.2rem; }
      .complete-freedom .blue-container {
        padding: 2rem 10rem; }
      .complete-freedom .footnotes {
        font-size: 1.4rem; }
      .complete-freedom h4 {
        margin-left: 6rem; }
      .complete-freedom .statistic-left {
        margin-bottom: 0;
        margin-left: 6rem; }
        .complete-freedom .statistic-left .value {
          width: auto; }
        .complete-freedom .statistic-left .label {
          width: auto;
          font-size: 2.5rem; }
        .complete-freedom .statistic-left .emphasis-green {
          display: block; } }
  .complete-freedom #gattex_volume_svg,
  .complete-freedom #gattex_calTime_svg,
  .complete-freedom #gattex_freedom_svg {
    width: 137px;
    height: 137px; }

/* Pediatrics */
#pediatrics-complete-freedom.complete-freedom {
  /*@media screen and (min-width: 1200px) {
        .indent-section {
            margin: 0 156px;
        }
    }*/ }
  #pediatrics-complete-freedom.complete-freedom h2 {
    display: block;
    margin: 0 auto 4rem auto; }
  #pediatrics-complete-freedom.complete-freedom h3 {
    margin-bottom: 4rem; }
  #pediatrics-complete-freedom.complete-freedom .icon-box {
    margin: 0;
    padding: 9.125rem 2rem 2rem;
    position: relative; }
    #pediatrics-complete-freedom.complete-freedom .icon-box .icon-box-img {
      transform: translateX(-50%);
      left: 50%;
      width: 13.7rem;
      height: 13.7rem;
      top: -1rem;
      position: absolute; }
      @media screen and (min-width: 768px) {
        #pediatrics-complete-freedom.complete-freedom .icon-box .icon-box-img {
          margin-top: 1rem; } }
    #pediatrics-complete-freedom.complete-freedom .icon-box p {
      background-color: #e3f0ed;
      border-radius: 1rem;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.5;
      padding: 5rem 2rem 1.5rem;
      text-align: center; }
      #pediatrics-complete-freedom.complete-freedom .icon-box p .emphasis-green {
        display: block; }
    #pediatrics-complete-freedom.complete-freedom .icon-box sup {
      font-size: 1rem; }
  #pediatrics-complete-freedom.complete-freedom .footnotes {
    margin-left: 0rem; }
  #pediatrics-complete-freedom.complete-freedom .value {
    width: 8rem; }
  #pediatrics-complete-freedom.complete-freedom h4 {
    font-size: 1.4rem; }
  #pediatrics-complete-freedom.complete-freedom .statistic-left {
    margin-top: .5rem; }
    @media screen and (min-width: 768px) {
      #pediatrics-complete-freedom.complete-freedom .statistic-left {
        margin-bottom: 2rem; } }
    #pediatrics-complete-freedom.complete-freedom .statistic-left .label {
      width: 75%;
      left: 0; }
      @media screen and (min-width: 768px) {
        #pediatrics-complete-freedom.complete-freedom .statistic-left .label {
          width: auto; } }
    #pediatrics-complete-freedom.complete-freedom .statistic-left .value-detail {
      left: 0; }
  #pediatrics-complete-freedom.complete-freedom .bullets {
    margin: 0 0 1rem 0; }
  @media screen and (min-width: 768px) {
    #pediatrics-complete-freedom.complete-freedom h2 {
      text-align: center;
      max-width: 74rem;
      /*&:after {
                bottom: -2rem;
            }*/ }
    #pediatrics-complete-freedom.complete-freedom h3 {
      padding-top: 2rem; }
    #pediatrics-complete-freedom.complete-freedom h4 {
      font-size: 1.6rem; }
    #pediatrics-complete-freedom.complete-freedom .icon-box {
      /*margin: 2rem 0;
            padding: 2rem;*/
      padding: 0;
      max-width: 75rem;
      margin: 4rem auto 0 auto; }
      #pediatrics-complete-freedom.complete-freedom .icon-box .icon-box-img {
        left: auto;
        top: -2rem; }
      #pediatrics-complete-freedom.complete-freedom .icon-box p {
        /*font-size: 2.2rem;
                padding: 2rem 2rem 2rem 18rem;
                text-align: left;*/
        padding: 3rem 5rem 1rem 13rem;
        font-size: 2.2rem;
        line-height: 2.6rem;
        min-height: 11.3rem;
        margin-bottom: 0;
        text-align: left; }
        #pediatrics-complete-freedom.complete-freedom .icon-box p .emphasis-green {
          display: inline; }
      #pediatrics-complete-freedom.complete-freedom .icon-box sup {
        font-size: 1.5rem; }
    #pediatrics-complete-freedom.complete-freedom .statistic-left .label {
      font-size: 2.3rem; }
    #pediatrics-complete-freedom.complete-freedom .statistic-left .value-detail {
      left: 0; }
    #pediatrics-complete-freedom.complete-freedom .bullets {
      margin: 0; }
    #pediatrics-complete-freedom.complete-freedom .indent-section {
      max-width: 63.5rem;
      margin: 5rem auto 0 auto; } }

.reduced-time-spent {
  padding: 3rem 0; }
  @media screen and (min-width: 768px) {
    .reduced-time-spent {
      padding-top: 10rem; } }
  .reduced-time-spent .header-set {
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media screen and (min-width: 768px) {
      .reduced-time-spent .header-set {
        flex-direction: row;
        max-width: 97rem;
        margin: 0 auto; } }
    .reduced-time-spent .header-set .header-icon {
      margin-bottom: 2rem; }
      @media screen and (min-width: 768px) {
        .reduced-time-spent .header-set .header-icon {
          margin-bottom: 0; } }
    .reduced-time-spent .header-set img {
      margin-bottom: 1rem; }
      @media screen and (min-width: 768px) {
        .reduced-time-spent .header-set img {
          width: 13.7rem;
          height: 13.7rem; } }
  .reduced-time-spent h2 {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 2rem;
    line-height: 2.3rem;
    padding: 0 1rem; }
    @media screen and (min-width: 768px) {
      .reduced-time-spent h2 {
        font-size: 2.8rem;
        text-align: left;
        line-height: 3.2rem;
        max-width: 74rem;
        padding-left: 3rem;
        margin: 0; } }
    .about-pediatrics-page .reduced-time-spent h2 {
      font-size: 1.8rem;
      line-height: 2.2rem;
      text-align: center;
      padding-left: 0;
      margin: 0 auto; }
      .about-pediatrics-page .reduced-time-spent h2::after {
        bottom: -3rem; }
      @media screen and (min-width: 768px) {
        .about-pediatrics-page .reduced-time-spent h2 {
          font-size: 2.8rem;
          line-height: 3.2rem; } }
    .reduced-time-spent h2:after {
      bottom: -2.5rem; }
  .reduced-time-spent h3 {
    color: #58595B;
    font-size: 1.8rem;
    line-height: 2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;
    padding: 0 1.5rem; }
    @media screen and (min-width: 768px) {
      .reduced-time-spent h3 {
        font-size: 2.2rem;
        line-height: 2.4rem; } }
    .reduced-time-spent h3 em {
      font-style: normal;
      color: #008A5E; }
    .about-pediatrics-page .reduced-time-spent h3 {
      margin-top: 6rem; }
  .reduced-time-spent h4 {
    color: #00402C;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.8rem; }
    @media screen and (min-width: 768px) {
      .reduced-time-spent h4 {
        max-width: 87rem;
        margin: 7rem auto 1rem;
        font-size: 1.6rem; } }
  .reduced-time-spent .bar-chart {
    border-bottom: 5px solid #009DDC;
    margin: 1rem 0 4rem;
    position: relative; }
    @media screen and (min-width: 768px) {
      .reduced-time-spent .bar-chart {
        max-width: 87rem;
        margin: 1rem auto 4rem auto;
        padding-bottom: 1rem; } }
    .reduced-time-spent .bar-chart .bar-chart-total {
      color: #009DDC;
      font-weight: 800;
      font-size: 2.2rem;
      position: absolute;
      right: 0;
      bottom: -2.8rem;
      text-align: right; }
      @media screen and (min-width: 768px) {
        .reduced-time-spent .bar-chart .bar-chart-total {
          bottom: -3.2rem; } }
    .reduced-time-spent .bar-chart .value {
      display: inline;
      font-size: 2.5rem;
      font-weight: bold;
      margin-left: 1rem;
      vertical-align: middle;
      position: relative; }
      @media screen and (min-width: 768px) {
        .reduced-time-spent .bar-chart .value {
          font-size: 6rem;
          line-height: 4.9rem;
          display: flex;
          flex-direction: column; } }
      .reduced-time-spent .bar-chart .value span:first-child {
        position: absolute;
        font-size: 1.2rem;
        left: 2.7rem;
        top: 0.5rem; }
        @media screen and (min-width: 768px) {
          .reduced-time-spent .bar-chart .value span:first-child {
            position: absolute;
            font-size: 3.2rem;
            left: 6.5rem;
            top: 0; } }
      .reduced-time-spent .bar-chart .value .value-detail {
        font-size: 1rem;
        vertical-align: middle;
        position: relative;
        left: 1rem;
        top: -.5rem; }
        @media screen and (min-width: 768px) {
          .reduced-time-spent .bar-chart .value .value-detail {
            font-size: 1.1rem;
            left: 0.5rem;
            top: -1.5rem; } }
    .reduced-time-spent .bar-chart .bar-green {
      background-color: #00794B;
      color: #fff;
      display: inline-block;
      font-weight: bold;
      margin-bottom: 0.5rem;
      padding: 1.5rem 1rem;
      font-size: 1rem;
      height: 4.2rem; }
      @media screen and (min-width: 768px) {
        .reduced-time-spent .bar-chart .bar-green {
          font-size: 1.7rem;
          padding: 2rem;
          height: 6.6rem; } }
      .reduced-time-spent .bar-chart .bar-green + div {
        color: #00794B; }
    .reduced-time-spent .bar-chart .bar-gray {
      background-color: #818386;
      color: #fff;
      display: inline-block;
      font-weight: bold;
      margin-bottom: 0.5rem;
      padding: 1.5rem 1rem;
      font-size: 1rem; }
      @media screen and (min-width: 768px) {
        .reduced-time-spent .bar-chart .bar-gray {
          font-size: 1.7rem;
          padding: 2rem;
          height: 6.6rem; } }
      .reduced-time-spent .bar-chart .bar-gray + div {
        color: #818386; }
  .reduced-time-spent #bar-chart-gattex, .reduced-time-spent #bar-chart-placebo {
    display: flex; }
  .reduced-time-spent .statistic-left {
    display: flex;
    margin: 2rem 0; }
    @media screen and (min-width: 768px) {
      .reduced-time-spent .statistic-left {
        max-width: 87rem;
        margin: 0 auto 2rem auto; } }
    @media screen and (min-width: 768px) {
      .about-pediatrics-page .reduced-time-spent .statistic-left {
        margin: 0 auto 5rem auto; } }
    .reduced-time-spent .statistic-left .value {
      border-right: 1px solid #C7C7C7;
      color: #00794B;
      font-size: 4rem;
      font-weight: bold;
      padding-right: 2rem;
      text-transform: uppercase;
      max-width: 9rem; }
      @media screen and (min-width: 768px) {
        .reduced-time-spent .statistic-left .value {
          font-size: 6rem;
          max-width: 11.5rem; } }
      .reduced-time-spent .statistic-left .value span {
        font-size: 65%;
        position: relative;
        top: -10px; }
    .reduced-time-spent .statistic-left .value-detail {
      font-size: 1rem;
      padding-left: 1rem; }
      @media screen and (min-width: 768px) {
        .reduced-time-spent .statistic-left .value-detail {
          padding-left: 1.5rem; } }
    .reduced-time-spent .statistic-left .label {
      font-size: 1.6rem;
      font-weight: bold;
      margin: 0.5rem 0;
      padding-left: 2rem; }
      @media screen and (min-width: 768px) {
        .reduced-time-spent .statistic-left .label {
          font-size: 2.5rem;
          max-width: 47.2rem; } }
      .reduced-time-spent .statistic-left .label em {
        color: #008A5E; }
  .reduced-time-spent .disclaimer {
    position: relative;
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: .5rem; }
    @media screen and (min-width: 768px) {
      .reduced-time-spent .disclaimer {
        font-size: 1.4rem;
        line-height: 1.7rem;
        max-width: 87rem;
        margin: 0 auto 1rem auto;
        padding-right: 1rem; } }
    .reduced-time-spent .disclaimer sup {
      position: relative; }
      .reduced-time-spent .disclaimer sup:first-child {
        /*position: absolute;
        top: 0.1rem;
        left: -0.5rem;*/ }
  .reduced-time-spent .icon-box {
    margin: 0;
    padding: 9.125rem 2rem 2rem;
    position: relative; }
    .about-pediatrics-page .reduced-time-spent .icon-box {
      padding-left: 0;
      padding-right: 0;
      margin-top: 5rem; }
      @media screen and (min-width: 768px) {
        .about-pediatrics-page .reduced-time-spent .icon-box {
          padding: 0;
          max-width: 75rem;
          margin: 4rem auto 0 auto; } }
    .reduced-time-spent .icon-box .icon-box-img {
      position: absolute;
      top: 0; }
      .about-pediatrics-page .reduced-time-spent .icon-box .icon-box-img {
        transform: translateX(-50%);
        left: 50%;
        width: 15.6rem;
        height: 15.6rem;
        top: -1rem; }
        @media screen and (min-width: 768px) {
          .about-pediatrics-page .reduced-time-spent .icon-box .icon-box-img {
            left: auto;
            top: -1rem; } }
    .reduced-time-spent .icon-box p {
      background-color: #E8F6FC;
      border-radius: 2rem;
      font-size: 1.9rem;
      font-weight: bold;
      line-height: 1.5;
      padding: 8rem 2rem 2rem;
      text-align: center; }
      .about-pediatrics-page .reduced-time-spent .icon-box p {
        background-color: #E3F0ED;
        font-size: 1.4rem;
        line-height: 1.8rem;
        padding: 4.5rem 3rem 1.5rem;
        border-radius: 1rem; }
        @media screen and (min-width: 768px) {
          .about-pediatrics-page .reduced-time-spent .icon-box p {
            padding: 4rem 5rem 1rem 13rem;
            font-size: 2.2rem;
            line-height: 2.6rem;
            min-height: 11.3rem;
            margin-bottom: 0;
            text-align: left; } }
      .reduced-time-spent .icon-box p .emphasis-green {
        display: block; }
        @media screen and (min-width: 768px) {
          .about-pediatrics-page .reduced-time-spent .icon-box p .emphasis-green {
            display: inline; } }
    .reduced-time-spent .icon-box sup {
      font-size: 1rem; }
  .reduced-time-spent .indent-section {
    margin: 0 auto;
    max-width: 87rem; }
    @media screen and (min-width: 768px) {
      .about-pediatrics-page .reduced-time-spent .indent-section {
        max-width: 63.5rem;
        margin: 5rem auto 6rem auto; } }
  .reduced-time-spent .emphasis-green {
    color: #008A5E; }
  .reduced-time-spent .statistic-right {
    border-bottom: 1px solid #C7C7C7;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    display: flex; }
    @media screen and (min-width: 768px) {
      .about-pediatrics-page .reduced-time-spent .statistic-right {
        padding-bottom: 3.6rem;
        margin-bottom: 3.6rem; } }
    .reduced-time-spent .statistic-right p {
      font-size: 1.6rem;
      line-height: 1.9rem;
      font-weight: 700;
      color: #58595B;
      text-align: right; }
    .reduced-time-spent .statistic-right .value {
      border-left: 1px solid #C7C7C7;
      color: #00794B;
      float: left;
      font-size: 5.5rem;
      font-weight: bold;
      padding-left: 1rem;
      text-align: center;
      text-transform: uppercase;
      width: 35%; }
      .about-pediatrics-page .reduced-time-spent .statistic-right .value {
        font-size: 4rem;
        text-align: left;
        width: 100%;
        max-width: 12.5rem;
        text-transform: none;
        display: flex;
        flex-direction: column; }
        @media screen and (min-width: 768px) {
          .about-pediatrics-page .reduced-time-spent .statistic-right .value {
            font-size: 6rem;
            max-width: 16rem;
            padding-left: 1.5rem; } }
        .about-pediatrics-page .reduced-time-spent .statistic-right .value .value-wrapper {
          display: flex; }
          .about-pediatrics-page .reduced-time-spent .statistic-right .value .value-wrapper .value-number {
            font-size: 4rem; }
            @media screen and (min-width: 768px) {
              .about-pediatrics-page .reduced-time-spent .statistic-right .value .value-wrapper .value-number {
                font-size: 6rem;
                margin-right: 0.5rem; } }
          .about-pediatrics-page .reduced-time-spent .statistic-right .value .value-wrapper .value-text {
            font-size: 1.6rem;
            position: relative;
            top: 0.7rem; }
            @media screen and (min-width: 768px) {
              .about-pediatrics-page .reduced-time-spent .statistic-right .value .value-wrapper .value-text {
                font-size: 2.2rem;
                top: 1rem; } }
        @media screen and (min-width: 768px) {
          .about-pediatrics-page .reduced-time-spent .statistic-right .value .value-detail {
            position: relative;
            left: 4rem;
            top: -1rem; } }
        .about-pediatrics-page .reduced-time-spent .statistic-right .value span {
          font-size: 1.6rem;
          position: relative;
          top: -1rem; }
          @media screen and (min-width: 768px) {
            .about-pediatrics-page .reduced-time-spent .statistic-right .value span {
              font-size: 2.2rem;
              top: -2rem; } }
    .reduced-time-spent .statistic-right .value-detail {
      font-size: 1rem; }
    .reduced-time-spent .statistic-right .label {
      font-size: 1.8rem;
      font-weight: bold;
      float: left;
      margin: 0.5rem 0;
      padding-right: 2rem;
      width: 65%; }
      .about-pediatrics-page .reduced-time-spent .statistic-right .label {
        font-size: 1.6rem;
        text-align: right;
        width: 100%;
        padding-right: 1.5rem; }
        @media screen and (min-width: 768px) {
          .about-pediatrics-page .reduced-time-spent .statistic-right .label {
            font-size: 2.5rem;
            line-height: 3rem; } }
  .reduced-time-spent .footnotes {
    font-size: 1rem; }
    @media screen and (min-width: 768px) {
      .reduced-time-spent .footnotes {
        font-size: 1.4rem; } }
  .reduced-time-spent #gattex_volume_svg,
  .reduced-time-spent #gattex_calTime_svg,
  .reduced-time-spent #gattex_freedom_svg {
    width: 137px;
    height: 137px; }

.volume-over-time {
  padding-bottom: 3rem; }
  @media screen and (min-width: 768px) {
    .volume-over-time {
      padding-bottom: 9rem; }
      .about-pediatrics-page .volume-over-time {
        padding-bottom: 7rem; } }
  .volume-over-time h2 {
    text-align: center;
    margin-bottom: 3rem;
    /*.about-adults-page & {
            @media screen and (min-width: 768px) {
                &:after {
                    bottom: -2.2rem;
                }
            }
        }*/ }
    @media screen and (min-width: 768px) {
      .volume-over-time h2 {
        max-width: 83rem;
        margin: 0 auto 4rem auto; }
        .volume-over-time h2:after {
          bottom: -2.2rem; } }
    .about-pediatrics-page .volume-over-time h2 {
      max-width: none;
      /*@media screen and (min-width: 768px) {
                &:after {
                    bottom: -2.2rem;
                }
            }*/ }
      .about-pediatrics-page .volume-over-time h2 + p {
        font-size: 1.4rem;
        line-height: 2rem;
        padding: 0px 4rem;
        text-align: center;
        font-weight: 500;
        margin-bottom: 0; }
        @media screen and (min-width: 768px) {
          .about-pediatrics-page .volume-over-time h2 + p {
            font-size: 1.6rem;
            max-width: none; } }
  .volume-over-time h3 {
    color: #58595B;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem; }
    @media screen and (min-width: 768px) {
      .volume-over-time h3 {
        font-size: 2.2rem;
        line-height: 2.2rem; } }
  .volume-over-time h4 {
    color: #00402C;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 1rem; }
    @media screen and (min-width: 768px) {
      .volume-over-time h4 {
        max-width: 76.8rem;
        margin: 0 auto 2rem auto;
        font-size: 1.6rem; } }
  .volume-over-time p {
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #464749;
    margin-bottom: 2rem; }
    @media screen and (min-width: 768px) {
      .volume-over-time p {
        max-width: 76.8rem;
        margin: 0 auto 2rem auto;
        font-size: 1.6rem; } }
  .volume-over-time .blue-sec {
    color: #2D9AE3;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 700;
    padding: 2rem;
    margin: 1rem auto; }
    @media screen and (min-width: 768px) {
      .volume-over-time .blue-sec {
        display: none; } }
  .volume-over-time .disclaimer {
    font-size: 1rem;
    margin-bottom: 0;
    line-height: 1.5rem; }
    @media screen and (min-width: 768px) {
      .volume-over-time .disclaimer {
        font-size: 1.4rem;
        line-height: 2rem; } }
  @media screen and (min-width: 768px) {
    .volume-over-time .chart {
      display: flex;
      justify-content: center;
      max-width: 98.8rem;
      margin: 5rem auto 4rem auto; }
      .about-pediatrics-page .volume-over-time .chart {
        margin-top: 2rem;
        margin-bottom: 0; } }
  .volume-over-time .chart .steps-chart-reduction-value {
    display: none; }
    @media screen and (min-width: 768px) {
      .volume-over-time .chart .steps-chart-reduction-value {
        display: flex;
        align-items: flex-end; }
        .volume-over-time .chart .steps-chart-reduction-value .figures {
          font-size: 4.4rem;
          line-height: 3rem;
          font-weight: 700;
          color: #008A5E;
          position: relative;
          margin-left: 2rem;
          margin-bottom: 10rem; }
          .volume-over-time .chart .steps-chart-reduction-value .figures span {
            font-size: 50%; }
          .volume-over-time .chart .steps-chart-reduction-value .figures:before {
            content: '';
            width: 2.2rem;
            height: 6.9rem;
            position: absolute;
            left: -2rem;
            top: -2rem;
            background-image: url(/dist/images/arrow-down-green.svg);
            background-repeat: no-repeat; } }
  .about-pediatrics-page .volume-over-time .chart {
    width: 100%;
    max-width: 73rem; }
    .about-pediatrics-page .volume-over-time .chart #gattex_chartPeds_svg .barlow-font {
      font-family: "Barlow", Arial, sans-serif;
      font-weight: 700; }

.safety-profile {
  padding: 3rem 0;
  background-color: #E3F0ED; }
  @media only screen and (min-width: 768px) {
    .safety-profile {
      padding: 10rem 0; } }
  .safety-profile h2 {
    text-align: center;
    padding: 0;
    margin-bottom: 5rem; }
    .safety-profile h2:after {
      margin-bottom: -1rem; }
    @media only screen and (min-width: 768px) {
      .safety-profile h2 {
        background-color: #E3F0ED;
        margin-bottom: 5rem;
        padding: 0 1rem; }
        .safety-profile h2:after {
          bottom: -2rem; } }
  .safety-profile h4 {
    font-size: 1.6rem; }
    @media only screen and (min-width: 768px) {
      .safety-profile h4 {
        font-size: 2.2rem; } }
  .safety-profile .pediatrics-subhead {
    margin-bottom: 3rem;
    /*@media only screen and (min-width: 768px) {
            margin-bottom: 5rem;
        }*/ }
  .safety-profile .table {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    border-collapse: collapse;
    margin: 3rem 0; }
    @media only screen and (min-width: 768px) {
      .safety-profile .table {
        margin: 5rem 0; } }
    .safety-profile .table tr td, .safety-profile .table tr th {
      border-bottom: 0.25px solid #464749;
      height: 30px;
      vertical-align: middle; }
      .safety-profile .table tr td:nth-child(1), .safety-profile .table tr th:nth-child(1) {
        color: #093220;
        text-align: left; }
      .safety-profile .table tr td:nth-child(2), .safety-profile .table tr th:nth-child(2) {
        color: #5a5b5e;
        text-align: center; }
      .safety-profile .table tr td:nth-child(3), .safety-profile .table tr th:nth-child(3) {
        color: #00794b;
        text-align: center; }
    .safety-profile .table tr th {
      border-bottom: 0.5px solid #093220;
      padding-bottom: 0.5rem; }
      .safety-profile .table tr th span {
        font-weight: 400; }
    .safety-profile .table tr:last-child td {
      border-bottom: 0; }
  .safety-profile ul {
    margin-bottom: 3rem; }
    @media only screen and (min-width: 768px) {
      .safety-profile ul {
        margin-bottom: 5rem; } }
    .safety-profile ul li {
      padding: 0 0 1rem 2rem;
      text-indent: -2rem; }
      .safety-profile ul li::before {
        content: "• ";
        color: #e19228;
        padding-right: 1rem; }
      .safety-profile ul li li {
        padding-top: 1rem; }
        .safety-profile ul li li::before {
          content: none; }
  .safety-profile .footnote {
    font-size: 1rem;
    padding-bottom: .3rem;
    text-indent: -1rem;
    padding-left: 1rem; }
    @media only screen and (min-width: 768px) {
      .safety-profile .footnote {
        font-size: 1.4rem; } }
    .safety-profile .footnote sup {
      font-size: 50%; }
  .safety-profile .no-textindent {
    text-indent: 0;
    padding-left: 0;
    margin-bottom: .8rem; }
    @media only screen and (min-width: 768px) {
      .safety-profile .no-textindent {
        margin-bottom: 1rem; } }

.timeline {
  padding-top: 3rem;
  padding-bottom: 3rem; }
  @media screen and (min-width: 768px) {
    .timeline {
      padding-top: 10rem;
      padding-bottom: 10rem; }
      .getting-started-page .timeline {
        padding-top: 0;
        padding-bottom: 3rem; } }
  .getting-started-page .timeline {
    padding-top: 0; }
    .getting-started-page .timeline .blue-sec {
      display: none; }
  .timeline .text-center {
    text-align: center; }
  .timeline .text-indent {
    text-indent: -22px;
    padding-left: 22px; }
  .timeline .text-bold {
    font-weight: 700; }
  .timeline h2 {
    margin-bottom: 3rem; }
    @media screen and (min-width: 768px) {
      .timeline h2 {
        margin-bottom: 5rem; } }
  .timeline sup {
    font-size: 65%;
    top: -6px; }
  .timeline h3 {
    color: #676767; }
  .timeline ul li sup, .timeline p sup {
    font-size: 12px;
    top: -3px; }
  .timeline ul li {
    padding: 0 0 10px 2rem;
    text-indent: -1.8rem; }
    .timeline ul li::before {
      content: "• ";
      color: #e19228;
      padding-right: 10px; }
  .timeline p.footnote {
    font-size: 1rem;
    padding-left: 2rem;
    text-indent: -2rem; }
    @media (min-width: 768px) {
      .timeline p.footnote {
        font-size: 1.4rem; } }
    .timeline p.footnote sup {
      font-size: 65%; }
      .timeline p.footnote sup.sect {
        font-size: 50%;
        top: -.5rem; }
  .timeline .table {
    width: 100%;
    border-collapse: collapse;
    color: #00402c;
    margin-bottom: 5rem;
    display: none; }
    @media (min-width: 768px) {
      .timeline .table {
        display: block; } }
    .timeline .table th {
      min-width: 150px;
      font-weight: 700;
      border-bottom: 1px solid #c6c5c5;
      padding: 1rem 0;
      position: sticky;
      top: 14.7rem;
      background-color: #FFFFFF;
      z-index: 1;
      color: #00402C; }
      @media (min-width: 768px) {
        .timeline .table th {
          font-size: 20px; } }
      @media (min-width: 768px) and (max-width: 1199px) {
        .timeline .table th {
          min-width: 90px; } }
      .timeline .table th sup {
        top: -5px;
        font-size: 16px; }
    .timeline .table tr td {
      border-bottom: 1px solid #c6c5c5;
      text-align: center;
      height: 40px;
      font-size: 14px;
      vertical-align: middle;
      padding: 0.5rem 0; }
      @media (min-width: 768px) {
        .timeline .table tr td {
          font-size: 20px; } }
      .timeline .table tr td:nth-child(1) {
        font-weight: 700;
        font-size: 18px;
        min-width: 100%;
        text-align: left;
        color: #008a5e; }
        @media (min-width: 768px) {
          .timeline .table tr td:nth-child(1) {
            min-width: 173px;
            font-size: 20px; } }
        .timeline .table tr td:nth-child(1) sup {
          font-size: 12px;
          top: -10px; }
      .timeline .table tr td:nth-child(2) {
        font-weight: 700;
        font-size: 13px;
        min-width: 100%;
        text-align: left; }
        @media (min-width: 768px) {
          .timeline .table tr td:nth-child(2) {
            font-size: 20px; } }
      .timeline .table tr td.no-border {
        border-bottom: 0; }
    .timeline .table tr:last-child td {
      border-bottom: 0; }
    .timeline .table tr-second th {
      min-width: 122px; }
      @media (min-width: 768px) and (max-width: 1199px) {
        .timeline .table tr-second th {
          min-width: 72px; } }
      .timeline .table tr-second th sup {
        top: -9px;
        font-size: 9px; }
    .timeline .table .mb-7 {
      margin-bottom: 5rem; }
  .timeline .table-mobile tr {
    font-weight: 700;
    height: 40px; }
    .timeline .table-mobile tr th {
      font-size: 18px;
      line-height: 22px;
      color: #008a5e;
      text-align: left;
      border-bottom: 1px solid #c6c5c5;
      vertical-align: middle; }
      .timeline .table-mobile tr th sup {
        top: -.6rem;
        font-size: 1rem; }
    .timeline .table-mobile tr td {
      color: #00402c;
      font-size: 13px;
      border-bottom: 1px solid #c6c5c5;
      vertical-align: middle; }
    .timeline .table-mobile tr:nth-of-type(1) th {
      padding-bottom: 1rem; }
    .timeline .table-mobile tr:nth-of-type(3) th sup {
      left: -2px; }
  @media screen and (min-width: 768px) {
    .timeline#adult-timeline .blue-sec p, .timeline#pediatrics-timeline .blue-sec p {
      font-size: 24px;
      font-weight: 700;
      max-width: 800px; } }
  .timeline .blue-sec {
    border-top: 3px solid #2d9ae3;
    border-bottom: 3px solid #2d9ae3;
    padding: 3rem;
    text-align: center;
    margin: 3rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 101.5rem;
    width: 100%; }
    .timeline .blue-sec p {
      color: #2d9ae3;
      font-size: 1.4rem;
      font-weight: 500; }
      .timeline .blue-sec p strong {
        font-weight: 700; }
      @media (min-width: 768px) {
        .timeline .blue-sec p {
          font-size: 1.8rem;
          max-width: none; } }
    .timeline .blue-sec a {
      color: #2d9ae3; }
  @media screen and (min-width: 768px) {
    .timeline .table-mobile {
      display: none; } }

.dosing-administration {
  background-color: #E3F0ED;
  padding: 2rem 0 3rem; }
  @media only screen and (min-width: 768px) {
    .dosing-administration {
      padding: 7rem 0; } }
  .dosing-administration h2 {
    text-align: center;
    margin: 3rem auto 4rem auto; }
    @media (min-width: 641px) {
      .dosing-administration h2 {
        margin: 0 0 5.5rem; } }
  .dosing-administration .copy {
    font-weight: 700; }
  .dosing-administration h4 {
    margin-bottom: 2rem;
    font-size: 1.6rem;
    padding: 0 .4rem; }
    @media only screen and (min-width: 768px) {
      .dosing-administration h4 {
        font-size: 2.2rem; } }
  .dosing-administration ul li {
    padding: 0 0 2rem 2rem;
    text-indent: -1.8rem;
    line-height: 1.8rem; }
    @media only screen and (min-width: 768px) {
      .dosing-administration ul li {
        line-height: 2rem; } }
    .dosing-administration ul li::before {
      content: "• ";
      color: #e19228;
      padding-right: 1rem; }
    .dosing-administration ul li li {
      padding-top: 1rem; }
      .dosing-administration ul li li::before {
        content: none; }
  .dosing-administration .white-callout {
    background-color: #fff;
    border-radius: 1rem;
    max-width: 74.1rem;
    padding: 3rem 1rem;
    margin: 1rem auto 4rem;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .dosing-administration .white-callout {
        margin: 4rem auto;
        padding: 4rem 1rem; } }
    .dosing-administration .white-callout h4 {
      text-align: center;
      margin-bottom: 5rem;
      color: #00402C; }
    .dosing-administration .white-callout + h4 {
      font-size: 1.8rem; }
    .dosing-administration .white-callout .weight-sec {
      display: flex;
      justify-content: center; }
      .dosing-administration .white-callout .weight-sec div {
        max-width: 11rem;
        margin-right: 3rem; }
        @media only screen and (min-width: 768px) {
          .dosing-administration .white-callout .weight-sec div {
            max-width: 18rem;
            margin-right: 4rem; } }
        .dosing-administration .white-callout .weight-sec div:last-child {
          margin-right: 0; }
        .dosing-administration .white-callout .weight-sec div p {
          font-weight: 500;
          border-top: 1px solid #676767;
          position: relative;
          padding-top: 1rem; }
          .dosing-administration .white-callout .weight-sec div p:nth-child(1) {
            min-height: 8rem; }
          .dosing-administration .white-callout .weight-sec div p.equal:after, .dosing-administration .white-callout .weight-sec div p.multiply:after, .dosing-administration .white-callout .weight-sec div p.divide:after {
            content: '=';
            font-size: 20px;
            font-weight: 700;
            position: absolute;
            top: -22px;
            right: -22px;
            font-weight: 700; }
          .dosing-administration .white-callout .weight-sec div p.multiply:after {
            content: '\00D7'; }
          .dosing-administration .white-callout .weight-sec div p.divide:after {
            content: '\00F7'; }
          .dosing-administration .white-callout .weight-sec div p span {
            font-weight: 700; }
            .dosing-administration .white-callout .weight-sec div p span.green {
              color: #008a5e; }
            .dosing-administration .white-callout .weight-sec div p span.blue {
              color: #009DDC; }
  .dosing-administration .mobile-none {
    display: none; }
    @media only screen and (min-width: 768px) {
      .dosing-administration .mobile-none {
        display: block; } }
