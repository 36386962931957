﻿.reduced-time-spent {
  padding: 3rem 0;

  @media screen and (min-width: 768px) {
    padding-top: 10rem;
  }

  .header-set {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      max-width: 97rem;
      margin: 0 auto;
    }

    .header-icon {
      margin-bottom: 2rem;

      @media screen and (min-width: 768px) {
        margin-bottom: 0;
      }
    }


    img {
      margin-bottom: 1rem;

      @media screen and (min-width: 768px) {
        width: 13.7rem;
        height: 13.7rem;
      }
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 2rem;
    line-height: 2.3rem;
    padding: 0 1rem;


    @media screen and (min-width: 768px) {
      font-size: 2.8rem;
      text-align: left;
      line-height: 3.2rem;
      max-width: 74rem;
      padding-left: 3rem;
      margin: 0;
    }

    .about-pediatrics-page & {
      font-size: 1.8rem;
      line-height: 2.2rem;
      text-align: center;
      padding-left: 0;
      margin: 0 auto;

      &::after {
        bottom: -3rem;
      }

      @media screen and (min-width: 768px) {
        font-size: 2.8rem;
        line-height: 3.2rem;
      }
    }

    &:after {
      bottom: -2.5rem;
    }
  }

  h3 {
    color: #58595B;
    font-size: 1.8rem;
    line-height: 2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;
    padding: 0 1.5rem;

    @media screen and (min-width: 768px) {
      font-size: 2.2rem;
      line-height: 2.4rem;
    }

    em {
      font-style: normal;
      color: #008A5E;
    }

    .about-pediatrics-page & {
      margin-top: 6rem;
    }
  }

  h4 {
    color: #00402C;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.8rem;

    @media screen and (min-width: 768px) {
      max-width: 87rem;
      margin: 7rem auto 1rem;
      font-size: 1.6rem;
    }
  }

  .bar-chart {
    border-bottom: 5px solid #009DDC;
    margin: 1rem 0 4rem;
    position: relative;

    @media screen and (min-width: 768px) {
      max-width: 87rem;
      margin: 1rem auto 4rem auto;
      padding-bottom: 1rem;
    }

    .bar-chart-total {
      color: #009DDC;
      font-weight: 800;
      font-size: 2.2rem;
      position: absolute;
      right: 0;
      bottom: -2.8rem;
      text-align: right;

      @media screen and (min-width: 768px) {
        bottom: -3.2rem;
      }
    }

    .value {
      display: inline;
      font-size: 2.5rem;
      font-weight: bold;
      margin-left: 1rem;
      vertical-align: middle;
      position: relative;

      @media screen and (min-width: 768px) {
        font-size: 6rem;
        line-height: 4.9rem;
        display: flex;
        flex-direction: column;
      }

      span:first-child {
        position: absolute;
        font-size: 1.2rem;
        left: 2.7rem;
        top: 0.5rem;

        @media screen and (min-width: 768px) {
          position: absolute;
          font-size: 3.2rem;
          left: 6.5rem;
          top: 0;
        }
      }


      .value-detail {
        font-size: 1rem;
        vertical-align: middle;
        position: relative;
        left: 1rem;
        top: -.5rem;



        @media screen and (min-width: 768px) {
          font-size: 1.1rem;
          left: 0.5rem;
          top: -1.5rem;
        }
      }
    }

    .bar-green {
      background-color: #00794B;
      color: #fff;
      display: inline-block;
      font-weight: bold;
      margin-bottom: 0.5rem;
      padding: 1.5rem 1rem;
      font-size: 1rem;
      height: 4.2rem;

      @media screen and (min-width: 768px) {
        font-size: 1.7rem;
        padding: 2rem;
        height: 6.6rem;
      }

      + div {
        color: #00794B;
      }
    }

    .bar-gray {
      background-color: #818386;
      color: #fff;
      display: inline-block;
      font-weight: bold;
      margin-bottom: 0.5rem;
      padding: 1.5rem 1rem;
      font-size: 1rem;

      @media screen and (min-width: 768px) {
        font-size: 1.7rem;
        padding: 2rem;
        height: 6.6rem;
      }

      + div {
        color: #818386;
      }
    }
  }

  #bar-chart-gattex, #bar-chart-placebo {
    display: flex;
  }

  .statistic-left {
    display: flex;
    margin: 2rem 0;

    @media screen and (min-width: 768px) {
      max-width: 87rem;
      margin: 0 auto 2rem auto;
    }

    .about-pediatrics-page & {
      @media screen and (min-width: 768px) {
        margin: 0 auto 5rem auto;
      }
    }

    .value {
      border-right: 1px solid #C7C7C7;
      color: #00794B;
      font-size: 4rem;
      font-weight: bold;
      padding-right: 2rem;
      text-transform: uppercase;
      max-width: 9rem;

      @media screen and (min-width: 768px) {
        font-size: 6rem;
        max-width: 11.5rem;
      }

      span {
        font-size: 65%;
        position: relative;
        top: -10px;
      }
    }

    .value-detail {
      font-size: 1rem;
      padding-left: 1rem;

      @media screen and (min-width: 768px) {
        padding-left: 1.5rem;
      }
    }

    .label {
      font-size: 1.6rem;
      font-weight: bold;
      margin: 0.5rem 0;
      padding-left: 2rem;

      @media screen and (min-width: 768px) {
        font-size: 2.5rem;
        max-width: 47.2rem;
      }

      em {
        color: #008A5E;
      }
    }
  }

  .disclaimer {
    position: relative;
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: .5rem;

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
      line-height: 1.7rem;
      max-width: 87rem;
      margin: 0 auto 1rem auto;
      padding-right: 1rem;
    }

    sup {
      position: relative;

      &:first-child {
        /*position: absolute;
        top: 0.1rem;
        left: -0.5rem;*/
      }
    }
  }

  .icon-box {
    margin: 0;
    padding: 9.125rem 2rem 2rem;
    position: relative;

    .about-pediatrics-page & {
      padding-left: 0;
      padding-right: 0;
      margin-top: 5rem;

      @media screen and (min-width: 768px) {
        padding: 0;
        max-width: 75rem;
        margin: 4rem auto 0 auto;
      }
    }

    .icon-box-img {
      position: absolute;

      .about-pediatrics-page & {
        transform: translateX(-50%);
        left: 50%;
        width: 15.6rem;
        height: 15.6rem;
        top: -1rem;

        @media screen and (min-width: 768px) {
          left: auto;
          top: -1rem;
        }
      }

      top: 0;
    }

    p {
      background-color: #E8F6FC;
      border-radius: 2rem;
      font-size: 1.9rem;
      font-weight: bold;
      line-height: 1.5;
      padding: 8rem 2rem 2rem;
      text-align: center;

      .about-pediatrics-page & {
        background-color: #E3F0ED;
        font-size: 1.4rem;
        line-height: 1.8rem;
        padding: 4.5rem 3rem 1.5rem;
        border-radius: 1rem;

        @media screen and (min-width: 768px) {
          padding: 4rem 5rem 1rem 13rem;
          font-size: 2.2rem;
          line-height: 2.6rem;
          min-height: 11.3rem;
          margin-bottom: 0;
          text-align: left;
        }
      }

      .emphasis-green {
        display: block;

        .about-pediatrics-page & {
          @media screen and (min-width: 768px) {
            display: inline;
          }
        }
      }
    }

    sup {
      font-size: 1rem;
    }
  }


  .indent-section {
    margin: 0 auto;
    max-width: 87rem;

    .about-pediatrics-page & {
      @media screen and (min-width:768px) {
        max-width: 63.5rem;
        margin: 5rem auto 6rem auto;
      }
    }
  }

  .emphasis-green {
    color: #008A5E;
  }

  .statistic-right {
    border-bottom: 1px solid #C7C7C7;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    display: flex;

    .about-pediatrics-page & {
      @media screen and (min-width:768px) {
        padding-bottom: 3.6rem;
        margin-bottom: 3.6rem;
      }
    }

    p {
      font-size: 1.6rem;
      line-height: 1.9rem;
      font-weight: 700;
      color: #58595B;
      text-align: right;
    }

    .value {
      border-left: 1px solid #C7C7C7;
      color: #00794B;
      float: left;
      font-size: 5.5rem;
      font-weight: bold;
      padding-left: 1rem;
      text-align: center;
      text-transform: uppercase;
      width: 35%;

      .about-pediatrics-page & {
        font-size: 4rem;
        text-align: left;
        width: 100%;
        max-width: 12.5rem;
        text-transform: none;
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 768px) {
          font-size: 6rem;
          max-width: 16rem;
          padding-left: 1.5rem;
        }

        .value-wrapper {
          display: flex;

          .value-number {
            font-size: 4rem;

            @media screen and (min-width: 768px) {
              font-size: 6rem;
              margin-right: 0.5rem;
            }
          }

          .value-text {
            font-size: 1.6rem;
            position: relative;
            top: 0.7rem;

            @media screen and (min-width: 768px) {
              font-size: 2.2rem;
              top: 1rem;
            }
          }
        }

        .value-detail {
          @media screen and (min-width: 768px) {
            position: relative;
            left: 4rem;
            top: -1rem;
          }
        }

        span {
          font-size: 1.6rem;
          position: relative;
          top: -1rem;

          @media screen and (min-width: 768px) {
            font-size: 2.2rem;
            top: -2rem;
          }
        }
      }
    }

    .value-detail {
      font-size: 1rem;
    }

    .label {
      font-size: 1.8rem;
      font-weight: bold;
      float: left;
      margin: 0.5rem 0;
      padding-right: 2rem;
      width: 65%;

      .about-pediatrics-page & {
        font-size: 1.6rem;
        text-align: right;
        width: 100%;
        padding-right: 1.5rem;

        @media screen and (min-width: 768px) {
          font-size: 2.5rem;
          line-height: 3rem;
        }
      }
    }
  }

  .footnotes {
    font-size: 1rem;

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
    }
  }

  #gattex_volume_svg,
  #gattex_calTime_svg,
  #gattex_freedom_svg {
    width: 137px;
    height: 137px;
  }
}
