﻿.timeline {
    padding-top: 3rem;
    padding-bottom: 3rem;

    @media screen and (min-width: 768px) {
        padding-top: 10rem;
        padding-bottom: 10rem;

        .getting-started-page & {
            padding-top: 0;
            padding-bottom: 3rem;
        }
    }

    .getting-started-page & {
        padding-top: 0;

        .blue-sec {
            display: none;
        }
    }


    .text-center {
        text-align: center;
    }

    .text-indent {
        text-indent: -22px;
        padding-left: 22px;
    }

    .text-bold {
        font-weight: 700;
    }

    h2 {
        margin-bottom: 3rem;

        @media screen and (min-width: 768px) {
            margin-bottom: 5rem;
        }
    }

    sup {
        font-size: 65%;
        top: -6px;
    }

    h3 {
        color: $bodyGray;
    }

    ul li, p {
        sup {
            font-size: 12px;
            top: -3px;
        }
    }


    ul li {
        padding: 0 0 10px 2rem;
        text-indent: -1.8rem;

        &::before {
            content: "• ";
            color: #e19228;
            padding-right: 10px;
        }
    }

    p {
        &.footnote {
            font-size: 1rem;
            padding-left: 2rem;
            text-indent: -2rem;

            @media (min-width: 768px) {
                font-size: 1.4rem;
            }

            sup {
                font-size: 65%;

                &.sect {
                    font-size: 50%;
                    top: -.5rem;
                }
            }
        }
    }

    .table {
        width: 100%;
        border-collapse: collapse;
        color: #00402c;
        margin-bottom: 5rem;
        display: none;

        @media (min-width: 768px) {
            display: block;
        }

        th {
            min-width: 150px;
            font-weight: 700;
            border-bottom: 1px solid #c6c5c5;
            padding: 1rem 0;
            position: sticky;
            top: 14.7rem;
            background-color: #FFFFFF;
            z-index: 1;
            color: #00402C;

            @media (min-width: 768px) {
                font-size: 20px;
            }

            @media (min-width: 768px) and (max-width: 1199px) {
                min-width: 90px;
            }

            sup {
                top: -5px;
                font-size: 16px;
            }
        }

        tr {

            td {
                border-bottom: 1px solid #c6c5c5;
                text-align: center;
                height: 40px;
                font-size: 14px;
                vertical-align: middle;
                padding: 0.5rem 0;

                @media (min-width: 768px) {
                    font-size: 20px;
                }

                &:nth-child(1) {
                    font-weight: 700;
                    font-size: 18px;
                    min-width: 100%;
                    text-align: left;
                    color: $green;

                    @media (min-width: 768px) {
                        min-width: 173px;
                        font-size: 20px;
                    }

                    sup {
                        font-size: 12px;
                        top: -10px;
                    }
                }

                &:nth-child(2) {
                    font-weight: 700;
                    font-size: 13px;
                    min-width: 100%;
                    text-align: left;

                    @media (min-width: 768px) {
                        font-size: 20px;
                    }
                }

                &.no-border {
                    border-bottom: 0;
                }
            }

            &:last-child {
                td {
                    border-bottom: 0;
                }
            }

            &-second {
                th {
                    min-width: 122px;

                    @media (min-width: 768px) and (max-width: 1199px) {
                        min-width: 72px;
                    }

                    sup {
                        top: -9px;
                        font-size: 9px;
                    }
                }
            }
        }





        .mb-7 {
            margin-bottom: 5rem;
        }
    }

    .table-mobile {

        tr {
            font-weight: 700;
            height: 40px;

            th {
                font-size: 18px;
                line-height: 22px;
                color: $green;
                text-align: left;
                border-bottom: 1px solid #c6c5c5;
                vertical-align: middle;

                sup {
                    top: -.6rem;
                    font-size: 1rem;
                }
            }

            td {
                color: #00402c;
                font-size: 13px;
                border-bottom: 1px solid #c6c5c5;
                vertical-align: middle;
            }

            &:nth-of-type(1) {
                th {
                    padding-bottom: 1rem;
                }
            }

            &:nth-of-type(3) {
                th {
                    sup {
                        left: -2px;
                    }
                }
            }
        }
    }

    &#adult-timeline, &#pediatrics-timeline {
        .blue-sec {
            p {
                @media screen and (min-width: 768px) {
                    font-size: 24px;
                    font-weight: 700;
                    max-width: 800px;
                }
            }
        }
    }

    .blue-sec {
        border-top: 3px solid #2d9ae3;
        border-bottom: 3px solid #2d9ae3;
        padding: 3rem;
        text-align: center;
        margin: 3rem auto;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 101.5rem;
        width: 100%;

        p {
            color: #2d9ae3;
            font-size: 1.4rem;
            font-weight: 500;

            strong {
                font-weight: 700;
            }

            @media (min-width: 768px) {
                font-size: 1.8rem;
                max-width: none;
            }
        }

        a {
            color: #2d9ae3;
        }
    }

    .table-mobile {
        @media screen and (min-width: 768px) {
            display: none;
        }
    }
}
