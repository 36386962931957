﻿/* Adult and Default */
.reduction-ps {
    margin-top: 1rem;
    padding: 0 2rem 2rem 2rem;

    @media screen and (min-width: 1024px) {
        margin-bottom: 4rem;
    }

    .container {
        padding: 0 .5rem;
    }


    h2 {
        margin: 4rem 0;
        text-align: center;

        .about-pediatrics-page & {
            margin-bottom: 4rem;

            @media screen and (min-width:768px) {
                max-width: 82rem;
                margin: 0 auto 4rem auto;
            }

            &:after {
                bottom: -2.5rem;
            }
        }

        .header-icon {
            display: block;
            margin: 2rem auto;
        }
    }

    section:not(:last-child) {
        margin-bottom: 2.5rem;
    }

    h3 {
        color: #58595B;
        margin-bottom: 1.5rem;
        text-align: center;

        &.after-30-months {
            font-size: 1.6rem;
            max-width: 81.4rem;

            @media screen and (min-width:768px) {
                font-size: 2.2rem;
            }
        }

        .about-pediatrics-page & {
            margin-top: 0;
            font-size: 1.6rem;

            @media screen and (min-width:768px) {
                font-size: 2.2rem;
                text-align: center;
            }
        }
    }

    h4 {
        color: #00402C;
        font-size: 1.7rem;
    }

    p {
        margin-bottom: 1rem;

        i {
            font-style: italic;
        }
    }

    li {
        margin-bottom: 1rem;

        &::before {
            color: #E19228;
            content: "•";
            margin: 0 0.5rem 0 -1.25rem;
        }
    }

    .emphasis-green {
        color: #008A5E;
    }

    .bar-chart {
        border-bottom: 5px solid #009DDC;
        margin: 2rem 0 6rem;
        position: relative;

        .bar-chart-total {
            color: #009DDC;
            font-weight: bold;
            font-size: 3rem;
            position: absolute;
            right: 0;
            bottom: -5rem;
            text-align: right;
        }

        .value {
            display: inline;
            font-size: 2.5rem;
            font-weight: bold;
            margin-left: 1rem;
            vertical-align: middle;
            position: relative;

            @media screen and (min-width: 768px) {
                display: flex;
                flex-direction: column;
            }

            span:first-child {
                position: absolute;
                font-size: 1.2rem;
                left: 2.7rem;
                top: 0.5rem;
            }

            .value-detail {
                font-size: 1rem;
                vertical-align: middle;
                position: relative;
                left: 1rem;
                top: -0.5rem;

                @media screen and (min-width: 768px) {
                    font-size: 1.1rem;
                    left: .5rem;
                    top: auto;
                }
            }
        }

        .bar-green {
            background-color: #00794B;
            color: #fff;
            display: inline-block;
            font-weight: bold;
            margin-bottom: 0.5rem;
            padding: 1rem;

            @media screen and (min-width: 768px) {
                padding: 2rem 1rem;
                max-height: 6.6rem;
            }

            + div {
                color: #00794B;
            }
        }

        .bar-gray {
            background-color: #818386;
            color: #fff;
            display: inline-block;
            font-weight: bold;
            margin-bottom: 0.5rem;
            padding: 1rem 1rem;

            @media screen and (min-width: 768px) {
                padding: 2rem 1rem;
                max-height: 6.6rem;
            }

            + div {
                color: #818386;
            }
        }
    }

    .statistic-left {
        display: inline-block;
        margin: 2rem 0;

        .about-pediatrics-page & {
            display: flex;
        }

        .value {
            border-right: 1px solid #C7C7C7;
            color: #00794B;
            float: left;
            font-size: 5.5rem;
            font-weight: bold;
            padding-right: 1rem;
            text-transform: uppercase;
            width: 35%;
            position: relative;

            @media screen and (min-width: 1024px) {
                padding-right: 4rem;
                margin-left: 0.8rem;
            }

            span:first-child {
                position: absolute;
                font-size: 3.2rem;
                left: 6rem;
                top: 0.5rem;
            }


            .about-pediatrics-page & {
                font-size: 4rem;
                text-align: left;
                width: 100%;
                max-width: 8rem;

                @media screen and (min-width: 768px) {
                    font-size: 6rem;
                    max-width: 11rem;
                }

                span {
                    font-size: 65%;
                    position: relative;
                    top: -1rem;
                    left: 0;

                    @media screen and (min-width: 768px) {
                        font-size: 50%;
                        top: -2rem;
                        left: 0;
                    }
                }
            }
        }

        .value-detail {
            font-size: 1rem;
            margin-left: 1.4rem;

            @media (min-width: 768px) {
                margin-top: -.5rem;
            }
        }

        .label {
            font-size: 1.8rem;
            font-weight: bold;
            float: left;
            margin: 0.5rem 0;
            padding-left: 2rem;
            width: 65%;

            .about-pediatrics-page & {
                font-size: 1.6rem;
                width: 100%;
                padding-left: 1.5rem;

                @media screen and (min-width: 768px) {
                    font-size: 2.5rem;
                    line-height: 3rem;
                }
            }
        }
    }

    .footnotes {
        font-size: 1rem;
        position: relative;

        @media screen and (min-width:768px) {
            max-width: 85.8rem;
            margin: 0 auto 5rem auto;
            font-size: 1.4rem;
            line-height: 1.7rem;
        }

        .daggers {
            position: absolute;
            top: .3rem;
            left: -.4rem;
        }
    }

    @media screen and (min-width: 641px) {
        h2::after {
            bottom: 2.5rem;
        }
    }

    @media screen and (min-width: 768px) {
        padding: 0rem 6rem 2rem;

        .about-pediatrics-page & {
            padding-top: 10rem;
        }

        h2 {
            display: block;
            text-align: left;
            max-width: 94.5rem;
            margin: 1rem auto;

            .header-icon {
                display: inline;
                margin-right: 1rem;
                vertical-align: middle;
            }

            .header-text {
                display: inline-block;
                margin-left: 1rem;
                vertical-align: middle;
                width: calc(100% - 156px - 3rem);
            }
        }

        h3 {
            margin: 0 auto 2rem auto;
            max-width: 75rem;

            &.after-30-months {
                max-width: 81.4rem;
            }
        }

        h4 {
            max-width: 86.5rem;
            margin: 0 auto 1rem;
        }

        .bar-chart {
            max-width: 86.5rem;
            margin: 2rem auto 6rem auto;

            .value {
                font-size: 6rem;
                line-height: 4rem;
                position: relative;

                span:first-child {
                    position: absolute;
                    font-size: 3.2rem;
                    left: 6.5rem;
                    top: -1rem;
                }
            }
        }

        .statistic-left {
            margin: 0 auto 2rem auto;
            max-width: 88rem;
            display: flex;

            .value {
                width: auto;
            }

            .label {
                font-size: 2.3rem;
                width: auto;
            }

            .emphasis-green {
                display: block;
            }
        }
    }

    hr {
        border: none;
        height: .1rem;
        background-color: #DC9F53;
    }

    #bar-chart-gattex, #bar-chart-placebo {
        @media screen and (min-width: 768px) {
            display: flex;
        }
    }

    #bar-chart-placebo {
        + p {
            font-size: 1rem;

            @media screen and (min-width: 641px) {
                font-size: 1.4rem;
            }
        }
    }

    #gattex_volume_svg,
    #gattex_calTime_svg,
    #gattex_freedom_svg {
        width: 137px;
        height: 137px;
    }
}
/* Pediatrics */
#pediatrics-reduction-ps.reduction-ps {
    .icon-box {
        margin: 0;
        padding: 9.125rem 2rem 2rem;
        position: relative;

        .about-pediatrics-page & {
            padding-left: 0;
            padding-right: 0;
            margin-top: 3rem;

            @media screen and (min-width: 768px) {
                padding: 0;
                max-width: 75rem;
                margin: 4rem auto 0 auto;
            }
        }

        .icon-box-img {
            position: absolute;

            .about-pediatrics-page & {
                transform: translateX(-50%);
                left: 50%;
                width: 13.7rem;
                height: 13.7rem;

                @media screen and (min-width: 768px) {
                    left: auto;
                    top: -1rem;
                }
            }

            top: 0;
        }

        p {
            background-color: #E8F6FC;
            border-radius: 2rem;
            font-size: 1.9rem;
            font-weight: bold;
            line-height: 1.5;
            padding: 8rem 2rem 2rem;
            text-align: center;

            .about-pediatrics-page & {
                background-color: #E3F0ED;
                font-size: 1.4rem;
                line-height: 1.8rem;
                padding: 5.5rem 3rem 1.5rem;
                border-radius: 1rem;

                @media screen and (min-width: 768px) {
                    padding: 3rem 5rem 1rem 13rem;
                    font-size: 2.2rem;
                    line-height: 2.6rem;
                    min-height: 11.3rem;
                    margin-bottom: 0;
                    text-align: left;
                }
            }

            .emphasis-green {
                display: block;

                .about-pediatrics-page & {
                    @media screen and (min-width: 768px) {
                        display: inline;
                    }
                }
            }
        }

        sup {
            font-size: 1rem;
        }
    }

    .footnotes {
        margin-left: 0rem;
    }

    @media screen and (min-width: 768px) {
        h2 {
            text-align: center;
        }
    }

    @media screen and (min-width: 1200px) {
        .indent-section {
            margin: 0 156px;
        }
    }

    .indent-section {
        .about-pediatrics-page & {
            @media screen and (min-width:768px) {
                max-width: 63.5rem;
                margin: 5rem auto 6rem auto;
            }

            .statistic-left {
                .value {
                    .value-detail {
                        left: 0.3rem;
                        position: relative;
                    }
                }
            }
        }
    }
}
